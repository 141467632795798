import React, { useEffect, useState } from 'react'
import './Login.css'
import { useNavigate, Link } from 'react-router-dom';
import Loader from './Loader';
const Login = (props) => {
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  const URL = window.globalURL
  const [Auth, setAuth] = useState({
    email: '',
    password: '',
  })

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onChange = (e) => {
    setAuth({ ...Auth, [e.target.name]: e.target.value })
  }

  const submitForm = async (e) => {
    localStorage.clear();
    e.preventDefault();

    if (!Auth.email || !Auth.password) {
      alert('Please enter your email and password to login');
      return;
    }
    if (!isValidEmail(Auth.email)) {
      alert('Please enter a valid email address.');
      return;
    }
    if (Auth.password.length < 8) {
      alert('Password must be at least 8 characters.');
      return;
    }

        setSpinner(true)


    try {
      const response = await fetch(`${URL}/login`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: Auth.email, password: Auth.password }),

      });
      const json = await response.json();
      if (json.message === "Login successful") {


        const accessToken = json.accessToken
        setSpinner(false)
        props.showAlert("Successfully signed in", 'success')
        localStorage.setItem("accessToken", accessToken)
        localStorage.setItem("user_email", json.email)
        localStorage.setItem("org_id", json.org_id)
        localStorage.setItem("user_type", json.user_type)
        navigate('/reports')
      }
      else {
        console.log(json);

            if (json.message.code === "UserNotConfirmedException"){
              localStorage.setItem('user_email', Auth.email)
              props.showAlert("Confirmation Code sent to mail (Check spam folder also)", 'success')
              navigate('/confirmation')
            }else{

            props.showAlert(json.message.message, 'error')
            setSpinner(false)

            }
     
      }
    }
    catch (error) {
      console.error('An error occurred:', error);
    }
  }

  return (

    <div>
      <div className="auth-wrapper">
        {spinner && <Loader />}
        <div className="auth-inner">

          <form onSubmit={submitForm}>
            <h3>Sign In</h3>
            <div className="mb-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                name='email'
                id='email'
                required
                onChange={onChange}
                 minLength={8}
                value={Auth.email}
              />
            </div>
            <div className="mb-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name='password'
                id='password'
                onChange={onChange}
                required
                value={Auth.password}
                minLength={8}
                maxLength={25}
              />
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            <p className="create-account">
              <Link to="/sign-up">Not Registered?      Create Account</Link>
            </p>
            <p className="forgot-password text-right">
              <Link to="/forget-password">Forgot password?</Link>
            </p>
          </form>

        </div></div></div>
  )
}

export default Login
