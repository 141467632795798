import React from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
const AllTxns = (props) => {
  const { realdata, value } = props

  // It is for AG grid Column Data

  var columns = [
    {
      field: `col0`,
      headerName: "Date",
      filter: true,
      width: 130,
      pinned: 'left',
    },
    {
      field: `col1`,
      headerName: "Description",
      filter: true,
       filter: 'agTextColumnFilter',
      width: 500
    },
    {
      field: `col2`,
      headerName: "Credit",
      filter: 'agNumberColumnFilter',
      width: 140
    },
    {
      field: `col3`,
      headerName: "Debit",
      filter: 'agNumberColumnFilter',
      width: 140
    },
    {
      field: `col4`,
      headerName: "Balance",
      filter: 'agNumberColumnFilter',
      width: 150,
      valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      }
    },
    {
      field: `col5`,
      headerName: "Acc Type",
      filter: true,
      width: 150
    },
    {
      field: `col6`,
      headerName: "Acc No",
      filter: true,
      width: 150
    },
    {
      field: `col7`,
      headerName: "Bank Name",
      filter: true,
      width: 150
    },
    {
      field: `col8`,
      headerName: "File Name",
      filter: true,
      width: 150
    },
    {
      field: `col9`,
      headerName: "Tags",
      filter: true,
      width: 150
    },
    {
      field: `col10`,
      headerName: "Category",
      filter: true,
      width: 150
    },
    {
      field: `col11`,
      headerName: "Counter Party",
      filter: true,
      width: 230
    }
  ]



  // It is for AG grid Row Data

  const rows = realdata[value] && realdata[value].length > 1
    ? realdata[value].slice(2).map((row, index) => {
      const rowData = { id: index };
      row.forEach((cell, cellIndex) => {
        if (cell === '-') {
          cell = 0;
        }
        rowData[`col${cellIndex}`] = cell || '-';
      });
      return rowData;
    })
    : [];

  // Applicable for all Column

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const rowStyle = {
    fontSize: '0.8em',
  }
  return (
    <div className="ag-theme-alpine"  >
      <AgGridReact
        rowData={rows} // Row Data for Rows
        columnDefs={columns} // Column Defs for Columns
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        defaultColDef={defaultColDef}
        rowStyle={rowStyle}

      />
    </div>
  )
}

export default AllTxns
