import * as React from 'react';
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import * as XLSX from 'xlsx';
import '../App.css'
import Loader from './Loader'
import ExcelComponent from './excelComponent/ExcelComponent';
import OverView from './excelComponent/OverView';
import CashFlowSummary from './excelComponent/CashFlowSummary';
import MonthlySummary from './excelComponent/MonthlySummary';
import CategoryCredit from './excelComponent/CategoryCredit';
import CounterPartyCredit from './excelComponent/CounterPartyCredit';
import CounterPartyMonthly from './excelComponent/CounterPartyMonthly';
import HighValue from './excelComponent/HighValue';
import AllTxns from './excelComponent/AllTxns';
import DailyBalance from './excelComponent/DailyBalance';
import SaleryTxn from './excelComponent/SaleryTxn';
import ChargesTxn from './excelComponent/ChargesTxn';
import LoanTxns from './excelComponent/LoanTxns';
import { Link, useNavigate } from 'react-router-dom'
import RecurringCredits from './excelComponent/ReccuringCredits';
import Loans from './excelComponent/Loans';
import ChequeReturn from './excelComponent/ChequeReturn';
import PartyWise from './excelComponent/PartyWise';

export default function ExcelReader(props) {
  const navigate = useNavigate();

  var firstKey = Object.keys(props.data)[0];
  var firstValue = props.data[firstKey];
  const [link, setLink] = useState(firstValue)
  const [linkKey, setLinkKey] = useState([])
  const [spinner, setSpinner] = useState(false);
  // sheet Number
  const [value, setValue] = useState('0');

  // Sheet Name
  const [sheetname, setSheetName] = useState([]);
  // All data By sheet
  const [dataBySheet, setDatabySheet] = useState({});

  const [colorpos, setColorPos] = useState(0)

  // Just to refresh Screen
  const [random, setrandom] = useState('singh');

  //  Particular data to each Click
  const [realdata, setrealdata] = useState({});
  const [debitData, setDebitData] = useState({})
  const [creditData, setCreditData] = useState({})
  const handleChange = async (event, newValue) => {
    setValue(newValue);
    // Checking all the conditions

    if (dataBySheet[newValue][0][0] === 'Category Credit') {
      realdata[newValue] = dataBySheet[newValue].map(row => row.slice(0, 7));
      realdata[newValue] = realdata[newValue].slice(0, 8);
      let moredata = dataBySheet[newValue].map(row => row.slice(8, 15));
      moredata = moredata.slice(2);
      const fullData = realdata[newValue].concat(moredata);
      realdata[newValue] = fullData
    }
    else if (dataBySheet[newValue][0][0] === 'Counter Party Credit') {
      realdata[newValue] = dataBySheet[newValue].map(row => row.slice(0, 6));
      creditData[newValue]  = realdata[newValue]
      const moredata = dataBySheet[newValue].map(row => row.slice(8, 14));
      debitData[newValue]  = moredata;
    }
    else if (dataBySheet[newValue][0][0] === 'High Value Credit') {
      realdata[newValue] = dataBySheet[newValue].map(row => row.slice(0, 4));
      creditData[newValue]  = realdata[newValue]
      const moredata = dataBySheet[newValue].map(row => row.slice(6, 10));
      debitData[newValue]  = moredata;
      const fulldata = realdata[newValue].concat(moredata);
      realdata[newValue] = fulldata
    }
    else if (dataBySheet[newValue][0][0] === 'Monthly Summary') {

      realdata[newValue] = dataBySheet[newValue];
      for (let i = 0; i < realdata[newValue][1].length; i += 2) {
        if (!realdata[newValue][1][i]) {
          realdata[newValue][1][i] = " "
        }
      }
    }
    else if (dataBySheet[newValue][0][0] === 'Overview') {
      realdata[newValue] = dataBySheet;
    }
    else {
      realdata[newValue] = dataBySheet[newValue];
    }

  }
  const checkComponent = () => {
    if (dataBySheet[value][0][0] === 'Overview') {
      return <OverView realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Cashflow Summary') {
      return <CashFlowSummary realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Monthly Summary') {
      return <MonthlySummary realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Category Credit') {
      return <CategoryCredit realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Counter Party Credit') {
      return <CounterPartyCredit creditData={creditData} debitData = {debitData} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Counter Party Monthly') {
      return <CounterPartyMonthly realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'High Value Credit') {
      return <HighValue creditData={creditData} debitData = {debitData} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'All Txns') {
      return <AllTxns realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Daily balance') {
      return <DailyBalance realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Salary Txns') {
      return <SaleryTxn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Electricity Txns') {
      return <ChequeReturn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Charges Txns') {
      return <ChargesTxn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Interest Txns') {
      return <ChequeReturn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'PartyWise Txns') {
      return <PartyWise realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Loan Txns') {
      return <LoanTxns realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Tax Txns') {
      return <ChequeReturn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Insurence Txns') {
      return <ChequeReturn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Rent Txns') {
      return <ChequeReturn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Chq RETN Txns') {
      return <ChequeReturn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Chq Txns') {
      return <ChequeReturn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Recurring Credits') {
      return <RecurringCredits realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Recurring Debits') {
      return <RecurringCredits realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Loans') {
      return <Loans realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Interest Txns') {
      return <ChequeReturn realdata={realdata} value={value} />
    }
    else if (dataBySheet[value][0][0] === 'Cash & ATM Txns') {
      return <ChequeReturn realdata={realdata} value={value} />
    }
    else {
      return <ExcelComponent realdata={realdata} value={value} />
    }
  }


  useEffect(() => {
    Object.keys(props.data).forEach(key => {
      const value = props.data[key];
      if (!linkKey.includes(key)) {
        linkKey.push(key);
      }
    })
    const fetchExcelData = async () => {
      setSpinner(true)
      const excelFileUrl = link; // Replace with the actual URL of your Excel file
      try {
        const response = await fetch(excelFileUrl);
        const arrayBuffer = await response.arrayBuffer();
        const data = new Uint8Array(arrayBuffer);
        // Process the Excel data
        const workbook = XLSX.read(data, { type: 'array' });
        for (let i = 0; i < workbook.SheetNames.length; i++) {
          const name = workbook.SheetNames[i];
          if (!sheetname.includes(name)) {
            sheetname.push(name)
          }
        }
        sheetname.map((e, i) => {
          const singh = workbook.Sheets[e];
          const sheetData = XLSX.utils.sheet_to_json(singh, { header: 1 });
          dataBySheet[e] =  sheetData
          setSpinner(false)
        })
      } catch {
        setSpinner(false)
      }
    };
    fetchExcelData();
    console.error = () => { };
  }, [link]);

  useEffect(() => {
    const fetchExcelData = async () => {
      setSpinner(true)
      const excelFileUrl = link; // Replace with the actual URL of your Excel file
      try {
        const response = await fetch(excelFileUrl);
        const arrayBuffer = await response.arrayBuffer();
        const data = new Uint8Array(arrayBuffer);
        // Process the Excel data
        const workbook = XLSX.read(data, { type: 'array' });
        for (let i = 0; i < workbook.SheetNames.length; i++) {
          const name = workbook.SheetNames[i];
          if (!sheetname.includes(name)) {
            sheetname.push(name)
          }
        }
        sheetname.map((e, i) => {
          const singh = workbook.Sheets[e];
          const sheetData = XLSX.utils.sheet_to_json(singh, { header: 1 });
          dataBySheet[e] =  sheetData
          setSpinner(false)
        })
      } catch {
        setSpinner(false)
      }
    };
    fetchExcelData();
    realdata['Overview'] = dataBySheet;
    setValue('Overview')
    console.error = () => { };
  }, [])
  return (
    <>
      {spinner && <Loader />}
      <div className='fileinput'>
      <div className="excel-page-heading">
        <h1> <u onClick={()=>navigate(-1)} style={{color: 'rgb(42, 83, 149)',cursor:'pointer'}}> Reports</u> &rarr; {sessionStorage.getItem('report_name')}</h1>
        </div>
        <a href={link}><button><i className="fa-solid fa-download"></i>Download Report</button></a>
      </div>

      {/* Home pahge is start */}

      <div className='home-page'>

        {/* Left all excel getters name */}
        <div className="left " >
          <div className='left-content'>
            {
              linkKey && linkKey.map((e, i) => {
                return (
                  <div key={i}>
                    <p style={{
              backgroundColor: colorpos === i ? '#00aaff' : 'white',
              color: colorpos === i ? 'white' : 'black',
              cursor: 'pointer',
            }} onClick={() => {
                      setLink(props.data[e])
                      setColorPos(i)
                    }} ><i className="fa-solid fa-star"></i>{e}</p>
                  </div>
                )
              })
            }
            <p><Link to="/uploaded-report"> <i className="fa-solid fa-gear"></i> Report Setting</Link></p>
          </div>


        </div>
        <div className="right">
          <Box sx={{ width: '100%', typography: 'body1' }} >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" variant='scrollable'>
                  {
                    sheetname.map((e, i) => {
                      return <Tab label={e} value={e} key={i} />
                    })
                  }
                </TabList>
              </Box>
            </TabContext>
          </Box>

          {/* it is for selected excel file will preview */}

          {dataBySheet[value] && dataBySheet[value].length > 0 && (
            <div  >
              {
                checkComponent()
              }

            </div>
          )}
        </div>
      </div>
    </>
  );
}



