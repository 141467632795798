import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css';
const CategoryCredit = (props) => {
  const {realdata, value} = props
  const [creditData, setCreditData] = useState({})
  const [debtiData, setDebitData] = useState({})
  const [date, setDate] = useState('Date')
    // It is for AG grid Column Data
    useEffect(()=>{
      creditData[value] = realdata[value].slice(0,8)
      debtiData[value] = realdata[value].slice(7,19)
      setDate('date')
    },[])
    var columns = [
        {
            field: `col0`,
            headerName: "Tags",
            width: 140,
            pinned: 'left',
        },
        {
            field: `col1`,
            headerName: "Start Date",
            width: 120,
        },
        {
            field: `col2`,
            headerName: "End Date",
            width: 120,
        },
        {
            field: `col3`,
            headerName: "Total Credit",
              width:150,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col4`,
            headerName: "Txn Count",
              width:140,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col5`,
            headerName: "Percentage",
              width:130,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col6`,
            headerName: "Avg Credit",
            width:150,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        }
    ]
    var columnsdebit = [
        {
            field: `col0`,
            headerName: "Tags",
            width: 140
        },
        {
            field: `col1`,
            headerName: "Start Date",
            width: 120,
        },
        {
            field: `col2`,
            headerName: "End Date",
            width: 120,
        },
        {
            field: `col3`,
            headerName: "Total Debit",
              width:130,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col4`,
            headerName: "Txn Count",
              width:130,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col5`,
            headerName: "Percentage",
              width:130,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col6`,
            headerName: "Avg Debit",
            width:130,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        }
    ]
      


  // It is for AG grid Row Data

  const rows = creditData[value] && creditData[value].length > 1
  ? creditData[value].slice(2).map((row, index) => {
    const rowData = { id: index };
    row.forEach((cell = 'none', cellIndex) => {
      rowData[`col${cellIndex}`] = cell || "none";
    });
    return rowData;
  })
  : [];

  const rowsDebit = debtiData[value] && debtiData[value].length > 1
  ? debtiData[value].slice(1).map((row, index) => {
    const rowData = { id: index };
    row.forEach((cell = 'none', cellIndex) => {
      rowData[`col${cellIndex}`] = cell || "none";
    });
    return rowData;
  })
  : [];

    // Applicable for all Column

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const rowStyle = {
    fontSize: '0.8em',
  }
  return (
    <div className="ag-theme-alpine twotableassigned"  >
    <AgGridReact
      rowData={rows} // Row Data for Rows
      columnDefs={columns} // Column Defs for Columns
      animateRows={true} // Optional - set to 'true' to have rows animate when sorted
      defaultColDef={defaultColDef}
      rowStyle={rowStyle}

    />
     <AgGridReact
      rowData={rowsDebit} // Row Data for Rows
      columnDefs={columnsdebit} // Column Defs for Columns
      animateRows={true} // Optional - set to 'true' to have rows animate when sorted
      defaultColDef={defaultColDef}
      rowStyle={rowStyle}

    />
  </div>
  )
}

export default CategoryCredit
