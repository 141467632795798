import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
const CounterPartyCredit = (props) => {
  const { debitData, creditData, value } = props
  // It is for AG grid Column Data
  const [credit, setCredit] = useState({})
  const [debit, setDebit] = useState({})
  const [date, setDate] = useState('Date')
  useEffect(()=>{
    debit[value] = debitData[value]
    credit[value] = creditData[value]
    setDate('date')
  },[])
  var columns = [
    {
      field: `col0`,
      headerName: "Counter Party",
      width: 300,
      pinned: 'left',
    },
    {
      field: `col1`,
      headerName: "Total credit",
        width:150,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
    },
    {
      field: `col2`,
      headerName: "No of Txns ",
        width:120,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
    },
    {
      field: `col3`,
      headerName: "Percentage",
        width:120,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
    },
    {
      field: `col4`,
      width: 120,
      headerName: "Start Date",
    },
    {
      field: `col5`,
      width: 120,
      headerName: "End Date",
    }
  ]
  var columnsdebit = [
    {
      field: `col0`,
      headerName: "Counter Party",
      width: 300
    },
    {
      field: `col1`,
      headerName: "Total Debit",
        width:150,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
    },
    {
      field: `col2`,
      headerName: "No of Txns ",
        width:120,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
    },
    {
      field: `col3`,
      headerName: "Percentage",
        width:120,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
    },
    {
      field: `col4`,
      width: 120,
      headerName: "Start Date",
    },
    {
      field: `col5`,
      width: 120,
      headerName: `End ${date}`,
    }
  ]



  // It is for AG grid Row Data

  const rows = credit[value] && credit[value].length > 1
    ? credit[value].slice(2).map((row, index) => {
      const rowData = { id: index };
      row.forEach((cell = 'none', cellIndex) => {
        rowData[`col${cellIndex}`] = cell || "none";
      });
      return rowData;
    })
    : [];
  const rowsDebit = debit[value] && debit[value].length > 1
    ? debit[value].slice(2).map((row, index) => {
      const rowData = { id: index };
      row.forEach((cell = 'none', cellIndex) => {
        rowData[`col${cellIndex}`] = cell || "none";
      });
      return rowData;
    })
    : [];

  // Applicable for all Column

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const rowStyle = {
    fontSize: '0.8em',
  }
 
  return (
    <div className="ag-theme-alpine twotableassigned"  >
      <AgGridReact
        rowData={rows} // Row Data for Rows
        columnDefs={columns} // Column Defs for Columns
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        defaultColDef={defaultColDef}
        rowStyle={rowStyle}
      />
      <AgGridReact
        rowData={rowsDebit} // Row Data for Rows
        columnDefs={columnsdebit} // Column Defs for Columns
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        defaultColDef={defaultColDef}
        rowStyle={rowStyle}
      />
    </div>
  )
}

export default CounterPartyCredit
