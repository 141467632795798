import React from 'react'
import { Link } from 'react-router-dom'

const PlanExpired = (props) => {
  return (
    <div className='subscription-over'>
<h3>Please pay your subscription amount to prevent account suspension.</h3>
<p>Thank you.</p>    

  <button onClick={()=>{props.planExpired(false)}}>Okay</button>
    </div>
  )
}

export default PlanExpired
