import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loader from './Loader' 
const UserDataPopUp = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  const [Auth, setAuth] = useState({
    user_email_id: localStorage.getItem('user_email'),
    user_access_token: localStorage.getItem('accessToken')
  })
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    account_type: "",
    org_name:"",
    user_notes:""    
  })
  const [spinner, setSpinner] = useState(false);
  const URL = window.globalURL
  const getData = async () => {
    try {
      setSpinner(true)
      setShow(false)
      const response = await fetch(`${URL}/get-my-account`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_email_id: Auth.user_email_id, user_access_token: Auth.user_access_token }),

      });
      const json = await response.json();
      if(json.message.message==="Invalid Access Token"){
        localStorage.clear();
        props.showDataPopUp(false)
        navigate('/login')
      }
      setUserData({
        name: json.message.end_user_name,
        email: json.message.end_user_email_id,
        phoneNo: json.message.end_user_phone_no,
        user_notes: json.message.end_user_notes,

        account_type: json.message.user_type === 'company_user'? 'Company User'
            : json.message.user_type === 'company_admin' ? 'Company Admin'
            : json.message.user_type === 'company_IND'?'Company User'
            : json.message.user_type === 'IND'?'Individual'
            : 'Unknown Type',

        org_name: json.message.end_user_org_name,
      })
      setShow(true)
      setSpinner(false);
    }
    catch (error) {
      console.error('An error occurred:', error);
    }
  }
  useEffect(() => {
    getData();
  }, [])
  return (
    <>
    {spinner &&<Loader/>}
    <div className='user-data' style={{display:!show?'none':'block'}} >
      <div className="details" >
        <div className="details-data">
          <div className="details-head"><i className="fa-solid fa-user"></i>Name:</div>
<div className="details-content">
    {userData.user_notes !== 'user_notes' ? (
      <a href={userData.user_notes} target="_blank" rel="noopener noreferrer" style={{color: 'blue'}}>
        {userData.name}
      </a>
    ) : (
      <span style={{color: 'blue'}}>{userData.name}</span>
    )}
  </div>

       </div>
        <div className="details-data">
          <div className="details-head"><i className="fa-solid fa-envelope"></i>Email Id:</div>
          <div className="details-content">{userData.email}</div>
        </div>
        <div className="details-data">
          <div className="details-head"><i className="fa-solid fa-phone"></i>Phone No:</div>
          <div className="details-content">{userData.phoneNo}</div>
        </div>

        <div className="details-data">
          <div className="details-head"><i className="fa-solid fa-check"></i>Org Name:</div>
          <div className="details-content">{userData.org_name}</div>
        </div>

       <div className="details-data">
          <div className="details-head"><i className="fa-solid fa-check"></i>My Account Type:</div>
          <div className="details-content">{userData.account_type}</div>
        </div>

        
      </div>
      <div className="okay-button" ><Link onClick={()=>{
        props.showDataPopUp(false)
      }}><button>Okay</button></Link></div>
    </div>
    </>
  )
}

export default UserDataPopUp
