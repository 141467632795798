import React, { useEffect, useState } from 'react'
import './css/Reports.css'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link, useNavigate } from 'react-router-dom';
import Loader from './Loader';
import ReportCheck from './ReportCheck';
import CreateNewReport from './CreateNewReport'
const TeamReports = (props) => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchHead, setSearchHead] = useState('report_name')
    const URL = window.globalURL
    const [rowData, setRawData] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [allData, setAllData] = useState([]);
    const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [randomNumber, setRandomNumber] = useState('');
    const [newReport, setNewReport] = useState(false);
    const [Auth, setAuth] = useState({
        user_access_token: localStorage.getItem('accessToken'),
        user_email_id: localStorage.getItem('user_email')
    })

    const [reportCheck, setReportCheck] = useState(false);
    const [reportDetails, setReportDetails] = useState();
    // AG Grid column data

    const [columnData, setColumnData] = useState([
        { headerName: 'Name', field: "report_name", maxWidth: 200, filter: 'agTextcolumnFilter' },
        { headerName: 'Reference ID', field: 'report_ref_id', maxWidth: 200 },
        {
            headerName: 'Status', field: 'status', filter: 'agTextcolumnFilter', maxWidth: 180, cellStyle: (params) => () => {
                if (params.value === 'Report Ready') {
                    return { color: 'green' }
                }
                else if (params.value === 'Process On Hold') {
                    return { color: 'red' }
                }
                else if (params.value === 'Processing...') {
                    return { color: 'blue' }
                }
                else {
                    return { color: 'black' }
                }
            }
        },
        { headerName: 'Created on', field: 'created_at', minWidth: 220 },
        { headerName: 'Last updated at', field: 'last_updated_at', minWidth: 220 },
        { headerName: 'Created By', field: "email_id", minWidth: 250 },
    ]);

    const fetchData = async () => {
        setSpinner(true)
        try {
            const response = await fetch(`${URL}/get-list-admin`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_access_token: Auth.user_access_token, user_email_id: Auth.user_email_id }),
            });
            const json = await response.json();


            if( json.message && json.message.code && (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException')){
                setSpinner(false);
                //props.showAlert("Session expired. Please log in again to continue...", 'error')
                localStorage.clear();
                navigate('/login')
                return;
            }
            
            setSpinner(false)
            setRawData(json)
            setAllData(json)
        }
        catch (error) {
            console.error('An error occurred:', error);
        }
    }
    useEffect(() => {
        fetchData();
        const min = 100000; // Minimum 6-digit number
        const max = 999999; // Maximum 6-digit number
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        setRandomNumber(randomNum);
    }, [])

    const rowStyle = {
        fontSize: '0.85em'
    }

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            filterParams: {
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            },
            autoHeight: true,

        }
    });


    const changepage = (e) => {
        const data = e.data.Results
        if (e.data.status === 'Report Ready'|| e.data.status === 'Processing...') {
            setReportDetails(e.data)
            setReportCheck(true)
            sessionStorage.clear();
            sessionStorage.setItem('user_report_id', e.data.report_id)
            sessionStorage.setItem('report_name', e.data.report_name)
            props.data(data)
        }
        else if (e.data.status === 'Process On Hold' ) {
            sessionStorage.clear();
            sessionStorage.setItem('user_report_id', e.data.report_id)
            sessionStorage.setItem('report_name', e.data.report_name)
            navigate('/uploaded-report')
        }
        else {
            sessionStorage.clear();
            sessionStorage.setItem('user_report_id', e.data.report_id)
            sessionStorage.setItem('report_name', e.data.report_name)
            navigate('/upload-file')
        }

    }
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const searchData = async (e) => {
        e.preventDefault()
        setSpinner(true)
        try {
            const response = await fetch(`${URL}/query-list`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_access_token: Auth.user_access_token, user_email_id: Auth.user_email_id, report_attr_name: searchHead, report_attr_value: searchQuery, report_query_index: `${searchHead}_index` }),
            });
            const json = await response.json();
            if( json.message && json.message.code && (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException')){
                setSpinner(false);
                //props.showAlert("Session expired. Please log in again to continue...", 'error')
                localStorage.clear();
                navigate('/login');
                return;
            }
            setSpinner(false)
            setRawData(json)
        }
        catch (error) {
            console.error('An error occurred:', error);
        }
    }

    // Grid portion


    const onPaginationChanged = async (e) => {
        const api = e.api;
        const currentPage = api.paginationGetCurrentPage() + 1;
        var content = true;
        const totalPages = api.paginationGetTotalPages();
        if (currentPage === totalPages && !loadingMoreData  && currentPage>1) {
            setLoadingMoreData(true)
            loadMoreData(api)
                .then(() => setLoadingMoreData(false))
                .catch((error) => {
                    console.error('An error occurred while loading more data:', error);
                    setLoadingMoreData(false);
                });
        }
    }
    const gridOptions = {
        pagination: true,
        paginationPageSize: 5,
    };
    const loadMoreData = async (api) => {
        function getLastPageLastRowData() {
            const totalRowCount = api.getDisplayedRowCount(); // Get the total row count in the displayed grid
            const pageSize = gridOptions.paginationPageSize; // Get the page size

            const lastPageStartRow = Math.max(totalRowCount - pageSize, 0); // Calculate the start row of the last page
            const lastPageEndRow = totalRowCount; // Calculate the end row of the last page

            const lastPageData = [];

            for (let rowIndex = lastPageStartRow; rowIndex < lastPageEndRow; rowIndex++) {
                const rowNode = api.getDisplayedRowAtIndex(rowIndex);
                if (rowNode) {
                    lastPageData.push(rowNode.data);
                }
            }

            return lastPageData;
        }
        const lastpagedata = getLastPageLastRowData();
        const lastdata = lastpagedata[lastpagedata.length - 1]
        const lastDataId = lastdata.report_id;
        try {
            const response = await fetch(`${URL}/get-list-admin`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_access_token: Auth.user_access_token, user_email_id: Auth.user_email_id, start_report_id: lastDataId, }),
            });
            const json = await response.json();

            if( json.message && json.message.code && (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException')){
                setSpinner(false);
                //props.showAlert("Session expired. Please log in again to continue...", 'error')
                localStorage.clear();
                navigate('/login')
                return;
            }
            if (json.length != 0) {
                setRawData((prevData) => [...prevData, ...json]);
            }
            else {
                return;
            }

        }
        catch (error) {
            console.error('An error occurred:', error);
        }

    }
    return (
        <div className='report-body'>
            {spinner && <Loader />}
            <div className="header">
                <div className="header-left">
                    <h1>Team Reports</h1>
                </div>
                <div className="header-right">
                <button className='refresh-button' onClick={
                        () => {
                            fetchData()
                        }
                    }> <i className="fa-solid fa-arrows-rotate"></i>Refresh</button>
                    <Link onClick={()=>{
                        setNewReport(true)
                    }}> <button className='new-report-button'>NEW REPORT</button></Link>
                </div>
            </div>
            <div className="reports">
                <div className="report-heading-filter">
               
                </div>
                <div className="report-table">
                    <div className="ag-theme-alpine">
                        <AgGridReact
                            onRowClicked={changepage}
                            rowData={rowData}
                            columnDefs={columnData}
                            animateRows={true}
                            rowStyle={rowStyle}
                            defaultColDef={defaultColDef}
                            gridOptions={gridOptions}
                            onPaginationChanged={onPaginationChanged}
                        />
                    </div>
                </div>
            </div>
            {reportCheck && <ReportCheck reportDetails={reportDetails} setReportCheck={setReportCheck} />}
            { newReport && <CreateNewReport randomNumber={randomNumber} newReport = {setNewReport}/>}
        </div>
    )
}

export default TeamReports