import React from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css';
const CashFlowSummary = (props) => {
  const {realdata, value} = props
    // It is for AG grid Column Data
  const jsondata = JSON.stringify(realdata[value]) 
    var columns = [
        {
            field: `col0`,
            headerName: "Month",
            width: 300,
            pinned: 'left',
        },
        {
            field: `col1`,
            headerName: "Credit",
            width:130,
      valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col2`,
            headerName: "Debit",
            width:130,
      valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN');
      } 
        },
        {
            field: `col3`,
            headerName: "NetCash Flow",
            width:140,
      valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col4`,
            headerName: "Avg Balance",
            width:150,
      valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col5`,
            headerName: "Txn Count (Credit)",
            width:140,
      valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col6`,
            headerName: "Txn Count (Debit)",
            width:140,
      valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col7`,
            headerName: "CHQ RETN",
            width:120,
      valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        }
    ]
      


  // It is for AG grid Row Data

  const rows = realdata[value] && realdata[value].length > 1
    ? realdata[value].slice(2).map((row, index) => {
      const rowData = { id: index };
      row.forEach((cell = 'none', cellIndex) => {
        rowData[`col${cellIndex}`] = cell || "none";
      });
      return rowData;
    })
    : [];

    // Applicable for all Column

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const rowStyle = {
    fontSize: '0.8em',
  }
  return (
    <div className="ag-theme-alpine"  >
    <AgGridReact
      rowData={rows} // Row Data for Rows
      columnDefs={columns} // Column Defs for Columns
      animateRows={true} // Optional - set to 'true' to have rows animate when sorted
      defaultColDef={defaultColDef}
      rowStyle={rowStyle}
    />
   
  </div>
  )
}

export default CashFlowSummary
