import React, { useState } from 'react'
import { useNavigate, Link  } from 'react-router-dom';
import Loader from './Loader';
const ConfirmForgotPassword = (props) => {

  const [spinner, setSpinner] = useState(false)
  const navigate = useNavigate();
  const URL = window.globalURL

  const [Auth, setAuth] = useState({
    verificationCode: '',
    email: localStorage.getItem('user_email'),
    newPassword: ''
  })

  const onChange = (e) => {
    setAuth({ ...Auth, [e.target.name]: e.target.value })
  }

  const submitForm = async (e) => {
    setSpinner(true)
    e.preventDefault();

try{
    const response = await fetch(`${URL}/confirm-forgot-password`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ verificationCode: Auth.verificationCode, email: Auth.email, newPassword: Auth.newPassword }),
    });
    const json = await response.json();
    if(json.message==="Password reset successful. You can now log in with your new password."){
    setSpinner(false)
    props.showAlert("Password changed successfully", 'success')
    navigate('/login')
    }
    else{
    props.showAlert("Invalid otp ", 'error')
    setSpinner(false)
    }
  }
  catch (error) {
    console.error('An error occurred:', error);
  }

    
  }
  return (
    <div>
      <div className="auth-wrapper">
        {spinner && <Loader />}
        <div className="auth-inner">

          {/* Form of entering the Confirm Forget Password */}

          <form onSubmit={submitForm}>
            <h3>Confirm Forget Password</h3>


              <div className="mb-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                name='email'
                id='email'
                disabled
                value={Auth.email}
              //placeholder="Enter email"
              />
            </div>

            <div className="mb-3">
              <label>Confirmation code (check email) </label>
              <input
                type="number"
                className="form-control"
                name='verificationCode'
                id='verificationCode'
                onChange={onChange}
                value={Auth.verificationCode}
                minLength={6}
                maxLength={6}
                required
              //placeholder="First name"
              />
            </div>
          

            <div className="mb-3">
              <label>New Password</label>
              <input
                type="password"
                className="form-control"
                name='newPassword'
                id='newPassword'
                autoComplete='off'
                onChange={onChange}
                minLength={8}
                maxLength={25}
                required
                value={Auth.newPassword}
              //placeholder="Enter password"
              />
            </div>
            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
             <p className="forgot-password text-right">
              <Link to="/login">Login</Link>
            </p>
        </div></div> </div>

  )
}

export default ConfirmForgotPassword
