import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import Loader from './Loader';

const ForgetPassword = (props) => {

  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false)
  const URL = window.globalURL

  const [email, setEmail] = useState('');

   const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
    };

  const onChange = (e) => {
    setEmail(e.target.value)
  }

  const submitForm = async (e) => {
   
    e.preventDefault();


    if (!email) {
      alert('Please enter your registered email to send confirmation code.');
      return;
    }
    if (!isValidEmail(email)) {
      alert('Please enter a valid email address.');
      return;
    }

 setSpinner(true)

try{
    const response = await fetch(`${URL}/forgot-password`, {
      method: "POST",
      headers:{
        'Content-Type':'application/json'
      },
      body: JSON.stringify({ email: email }),
    });

    const json = await response.json();
    console.log(json)
    if(json.message ==='Password reset initiated. Please check your email for further instructions.'){
        localStorage.setItem('user_email', email)
        setSpinner(false)
        props.showAlert("If your email id registered with us then you will get the passcode to your email id", 'success')
        navigate('/confirm-forget-password')
    }
    else if (json.message ==='User not confirmed. Please confirm your account.'){
    props.showAlert("Your email not confirmed yet. Confirm the account after that change the password. We have sent the code to email", 'error')
    localStorage.setItem('user_email', email)
    navigate('/confirmation')
    setSpinner(false)
    }
    else{
    props.showAlert("Some Error Occured...", 'error')
    setSpinner(false)

    }

  }
  catch (error) {
    console.error('An error occurred:', error);
  }
  }
  return (
    <div>
      <div className="auth-wrapper">
      {spinner && <Loader/>}
        <div className="auth-inner">

          <form onSubmit={submitForm}>
            <h3>Forget Password ?</h3>
            <div className="mb-3">
              <label>Registered Email</label>
              <input
                type="email"
                className="form-control"
                onChange={onChange}
                value={email}
                name='email'
                id='email'
                required
                maxLength={75}
              />
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                Next
              </button>
            </div>
               <p className="forgot-password text-right">
              <Link to="/login">Login</Link>
            </p>
          </form>
          
          </div></div></div>
  )
}

export default ForgetPassword
