import React, { useEffect, useState } from 'react'
import './css/NewReports.css'
import Loader from './Loader'
import { Link } from 'react-router-dom'
import UplaodedReportHomepage from './UploadedReportHomePage'
import { useNavigate  } from 'react-router-dom';
const UplaodedReport = (props) => {
  const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false)
  const [data, setData] = useState([]);
  const URL = window.globalURL
  const [Auth, setAuth] = useState({
    user_access_token: localStorage.getItem('accessToken'),
    user_report_id: sessionStorage.getItem('user_report_id'),
    user_report_name:sessionStorage.getItem('report_name'),
    user_email_id: localStorage.getItem('user_email')
  })

const fetchData = async () => {

    setSpinner(true);
    try {
      const response = await fetch(`${URL}/get-account`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_access_token: Auth.user_access_token, user_report_id: Auth.user_report_id, user_email_id: Auth.user_email_id }),
      });

      const json = await response.json();
       if( json.message && json.message.code && (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException')){
        setSpinner(false);
        props.showAlert("Session expired. Please log in again to continue...", 'error')
        localStorage.clear();
        navigate('/login')
        return;
      }
      setData(json);
      setSpinner(false);
    }
    catch (error) {
      console.error('An error occurred:', error);
      setSpinner(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, [])
  return (
    <>
      <div>
        <div className="back">

        </div>
        <div className="report-page-heading">
          <h1> <u onClick={()=>navigate(-1)} style={{color: 'rgb(42, 83, 149)',cursor:'pointer'}}> Reports</u> &rarr; {Auth.user_report_name}</h1>
        </div>
         {Object.keys(data).map((key, index) => (
          <div key={index}>
            <UplaodedReportHomepage  bankDetails={data[key]}  />
          </div>
        ))}
        <div className='add-account'>
          {spinner && <Loader />}
          <button className='button-upload add-account-uplaod disabledbutton' disabled  ><i className="fa-solid fa-plus"></i>Add New Account</button>
        </div>
        <div className="row-input row-input-button-uplaod" >
          <button className='button-upload save-uplaod disabledbutton' disabled type="submit"  ><i className="fa-regular fa-floppy-disk "></i>Save</button>
          <Link to='/reports'> <button disabled className='button-upload cancel-uplaod disabledbutton' type="reset"><i className="fa-solid fa-xmark"></i>Cancel</button></Link>
        </div>
      </div>
    </>
  )
}

export default UplaodedReport;
