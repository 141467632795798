import React, { useEffect, useState } from 'react'
import Loader from './Loader'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useNavigate  } from 'react-router-dom';
import PlanUpgrad from './PlanUpgrad'
const NewReportHomePage = (props) => {
    const navigate = useNavigate();
    
    const [spinner, setSpinner] = useState(false)
    const [fileName, setFileName] = useState([]);
    const [uplaodedFile, setUplaodedFile] = useState([]);
    const [showdlticon, setshowdlticon] = useState('block')
    let [filenumber, setFileNumber] = useState();
    const [showPlanUpgrad, setShowPlanUpgrad] = useState(false);
    const [showPlanUpgradE, setShowPlanUpgradE] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    // For delete the account the inputs will be as 
    const [dltAccountData, setDltAccountData] = useState({
        user_access_token: localStorage.getItem('accessToken'),
        user_email_id: localStorage.getItem('user_email'),
        user_report_id: sessionStorage.getItem('user_report_id'),
        acc_id: props.acc_id

    })
    const [dltFileData, setDltFileData] = useState({
        user_access_token: localStorage.getItem('accessToken'),
        user_email_id: localStorage.getItem('user_email'),
        user_report_id: sessionStorage.getItem('user_report_id'),
        acc_id: props.acc_id,
        file_id: ''
    })

    //  for Uplaod file inputswill be as

    const [UplaodFile, setUploadFile] = useState({
        files: '',
        user_access_token: localStorage.getItem('accessToken'),
        user_email_id: localStorage.getItem('user_email'),
        user_report_id: sessionStorage.getItem('user_report_id'),
        acc_id: props.acc_id,
        file_id: ''

    })

    const URL = window.globalURL

    // For bak details form data will be as

    const [bankDetails, setBankDetais] = useState({
        acc_id: props.acc_id,
        name: props.bankDetails.bank_name,
        type: props.bankDetails.acc_type,
        number: props.bankDetails.acc_no,
        od: props.bankDetails.od_limit,
        analysis_from: props.bankDetails.ana_from === 'null' ? '' : props.bankDetails.ana_from,
        analysis_to: props.bankDetails.ana_to === 'null' ? '' : props.bankDetails.ana_to
    })

    const [amount, setAmount] = useState('')
    const jsonObject = {
        [props.acc_id]: {},
    };
    const createJSONBhai = () => {
        jsonObject[props.acc_id]['acc_no'] = bankDetails.number;
        jsonObject[props.acc_id]['acc_type'] = bankDetails.type;
        jsonObject[props.acc_id]['od_limit'] = bankDetails.od;
        jsonObject[props.acc_id]['ana_to'] = bankDetails.analysis_to;
        jsonObject[props.acc_id]['ana_from'] = bankDetails.analysis_from;
        jsonObject[props.acc_id]['bank_name'] = bankDetails.name;
        return true
    }
    const onChange = async (e) => {
        setBankDetais({ ...bankDetails, [e.target.name]: e.target.value })

    }
    const formatCustomDate = (date) => {
        if (date) {
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            return `${day} ${month} ${year}`;
        }
    }
    const onDateAnaFromChange = (date) => {
        date = formatCustomDate(date)
        setBankDetais({ ...bankDetails, ['analysis_from']: date })

    }
    const onDateAnaToChange = (date) => {
        date = formatCustomDate(date)
        setBankDetais({ ...bankDetails, ['analysis_to']: date })

    }
    useEffect(() => {
        if (props.submitForm) {
            arrayCall();
            if (uplaodedFile.length != 0) {
                localStorage.setItem('fileAvailable', 'true')
            }
            createJSONBhai();
            props.getfromchild(jsonObject)
        }
        if (props.index === 0) {
            setshowdlticon('none')
        }
    }, [props.submitForm]);

    const dltAccount = async () => {
        // filenumber -= 1;
        setSpinner(true)
        try {
            const response = await fetch(`${URL}/delete-account`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_access_token: dltAccountData.user_access_token, user_report_id: dltAccountData.user_report_id, user_email_id: dltAccountData.user_email_id, acc_id: dltAccountData.acc_id }),
            });

            const json = await response.json();
            setSpinner(false);
            if (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException'){
                setSpinner(false);
                props.showAlert("Session expired. Please log in again to continue...", 'error')
                localStorage.clear();
                navigate('/login');
                return;
            }
            props.showAlert("Successfully account deleted", 'success');
            let dummyalldata = props.allData;
            const indexToRemove = dummyalldata.findIndex(account => dltAccountData.acc_id in account)
            if (indexToRemove !== -1) {
                dummyalldata.splice(indexToRemove, 1);
            }
            props.setallData(dummyalldata);
            props.setsingh.delete(dltAccountData.acc_id)
            props.fetchData();
        }
        catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const uplaodedChange = async (e) => {
        setSpinner(true)
        console.log(filenumber)
        if (filenumber <= 18) {
            filenumber += 1;
            props.spin(true);
            const file = e.target.files[0];
            if (e.target.files[0].size < 20000 * 1024) {
                console.log(e.target.files[0].size)
                const fileid = `${Date.now()}__${file.name}`

                const formData = new FormData();

                formData.append('files', file)
                formData.append('user_access_token', UplaodFile.user_access_token)
                formData.append('user_report_id', UplaodFile.user_report_id)
                formData.append('acc_id', props.acc_id)
                formData.append('user_email_id', UplaodFile.user_email_id)
                formData.append('file_id', fileid)
                try {
                    const response = await fetch(`${URL}/upload-files`, {
                        method: "POST",
                        body: formData,
                    });
                    const json = await response.json();
                    if (json.message === "File Uploaded!") {

                        console.log(json.body);
                        
                        const result = json.body;
                        const acc_id_res = result.acc_id||'null';
                        const accountNo_res = result.accountNo !== undefined && result.accountNo !== null && result.accountNo !== '' ? result.accountNo : 'null';
                        const accountType_res = result.accountType !== undefined && result.accountType !== null && result.accountType !== '' ? result.accountType : 'null';
                        const bankName_res = result.bankName !== undefined && result.bankName !== null && result.bankName !== '' ? result.bankName : 'null';
                        const anaFrom_res = result.anaFrom !== undefined && result.anaFrom !== null && result.anaFrom !== '' ? result.anaFrom : 'null';
                        const anaTo_res = result.anaTo !== undefined && result.anaTo !== null && result.anaTo !== '' ? result.anaTo : 'null';
                        const openBal_res = result.openBal !== undefined && result.openBal !== null && result.openBal !== '' ? result.openBal : 'null';
                        const closeBal_res = result.closeBal !== undefined && result.closeBal !== null && result.closeBal !== '' ? result.closeBal : 'null';

                        console.log('analysis from',anaFrom_res);
                        console.log('analysis fto',anaTo_res);

                        setBankDetais((prevBankDetails) => {
                        const updatedBankDetails = {
                            ...prevBankDetails,        
                            acc_id: acc_id_res,           
                            number: accountNo_res,        
                            name: bankName_res,           
                            type: accountType_res,        
                            ...(anaFrom_res !== 'null' && { analysis_from: anaFrom_res }),   
                            ...(anaTo_res !== 'null' && { analysis_to: anaTo_res }),  
                        };
                        return updatedBankDetails;      
                        });
                        console.log('Updatedd..');

                        await props.fetchData()
                        setSpinner(false);
                        props.spin(false);
                        props.showAlert('Uploaded the file successfully', 'success');
                        arrayCall();
                    }
                    else if (json.message === "Password Needed") {
                        const jsonObject = JSON.parse(json.body);
                        let pdfacc_id = jsonObject.acc_id;
                        let file_path = jsonObject.file_path;
                        let file_name = jsonObject.file_name;
                        
                        setSpinner(false)
                        props.spin(false)
                        showPasswordForm(pdfacc_id, file_path, file_name);
                    }else if (json.message === "SCANNED_DOCUMENT") {
                        console.log('Came Scanned Document....')
                        setSpinner(false);
                        const defaultData = {
                        title: 'Uploaded file is a scanned document. Try other files',
                        description: '(To process this scanned document, you need to upgrade to premium plus membership)',
                        link: 'https://proanalyser.in/more-info-scanned-documents/',
                        icon: 'fa-image'
                        };
                        setApiResponse(defaultData)
                        props.spin(false);
                        setShowPlanUpgrad(true);
                    }else if (json.message === "ENCRYPTED_PDF") {

                        console.log('Came Encrpted Document....')
                        const defaultData = {
                        title: 'Uploaded file is a encrypted document. Try other files',
                        description: '(To process this encrypted document, you need to upgrade to premium plus membership)',
                        link: 'https://proanalyser.in/more-info-encrypted-documents/',
                        icon: 'fa-lock'
                        };
                        setApiResponse(defaultData)
                        props.spin(false)
                        setSpinner(false);
                        
                        setShowPlanUpgrad(true);
                    }
                     else if (json.message === "ACCOUNT_NUMBER_MISMATCH") {
                        const defaultData = {
                        title: 'Uploaded file account number does not match with already uploaded file.',
                        description: '(To process multiple bank accounts you need to click "Add New Account" button below and upload this file there.)',
                        link: 'https://proanalyser.in/more-info-proanlyser-webapp/',
                        icon: 'fa-triangle-exclamation'
                        };
                        setSpinner(false)
                        props.spin(false)
                        setApiResponse(defaultData)
                        setShowPlanUpgrad(true);
                        //props.showAlert('Account number mismatch. Make sure to upload correct bank statements to correct account', 'error');  
                    }else if (json.message === "UNSUPPRTED_FORMAT") {
                        setSpinner(false)
                        props.spin(false)
                        const defaultData = {
                        title: 'Uploaded document is in unsupported format',
                        description: '(Right now we support PDF,EXCEL,CSV formats)',
                        link: 'https://proanalyser.in/more-info-proanlyser-webapp/',
                        icon: 'fa-triangle-exclamation'
                        };
                        setApiResponse(defaultData)
                        setShowPlanUpgrad(true);
                    }
                    if (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException'){
                        setSpinner(false);
                        props.spin(false)
                        props.showAlert("Session expired. Please log in again to continue...", 'error')
                        localStorage.clear();
                        navigate('/login')
                        return;
                    }
                }
                catch (error) {
                    setSpinner(false)
                    props.spin(false)
                    console.error('An error occurred:', error);
                }
            }
            else {
                setSpinner(false)
                props.spin(false)
                props.showAlert('File size should not be more than 20 MB', 'error');
                setSpinner(false)
            }

        }
        else {
            setSpinner(false)
            props.spin(false)

            props.showAlert('File upload limit exceeded. (Maximum 18 Files Per Account)','error');
            setSpinner(false)

        }

    }
    const dltFile = async (fileId) => {
        setSpinner(true);
        try {
            const response = await fetch(`${URL}/delete-file`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_access_token: dltFileData.user_access_token, acc_id: dltFileData.acc_id, user_report_id: dltFileData.user_report_id, user_email_id: dltFileData.user_email_id, file_id: fileId }),
            });
            const json = await response.json();
            if (json.message === "File deleted successfully") {
                await props.fetchData();
                setSpinner(false);
                props.showAlert('Successfully deleted the file', 'success');
                arrayCall();
            }
           else if (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException'){
                setSpinner(false);
                props.showAlert("Session expired. Please log in again to continue...", 'error')
                localStorage.clear();
                navigate('/login')
                return;
            }
            else {
                props.showAlert('File Not Deleted', 'error');
            }

        }
        catch (error) {
            console.error('An error occurred:', error);
        }
    }
    const arrayCall = () => {
         console.log('props.bankDetails..............',props.bankDetails.files);
         const fileProperties = props.bankDetails.files;
        fileName.length = 0;
        for (const accountName in fileProperties) {
            console.log('accountName ..............',accountName);
            if (accountName.endsWith("__FileID") || accountName.endsWith("__FilesID")) {
                if (!fileName.includes(props.bankDetails.files[accountName])) {
                    fileName.push(props.bankDetails.files[accountName])
                }
            }

        }
        setUplaodedFile(fileName)
        setFileNumber(uplaodedFile.length)
    }
    useEffect(() => {
        arrayCall();
    })
    const showPasswordForm = (accid, filepath, filename) => {
        document.getElementById('password-form').style.display = 'flex';
        const form = document.getElementById('password-form');
        if (form) {
            form.addEventListener('submit', async (event) => {
                const password = document.getElementById('pswd').value;
                event.preventDefault();
                setSpinner(true)
                const response = await fetch(`${URL}/check-password`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ user_access_token: UplaodFile.user_access_token, user_report_id: UplaodFile.user_report_id, user_email_id: UplaodFile.user_email_id, acc_id: accid, file_name: filename, file_path: filepath, password: password }),
                });
                const json = await response.json();
                if (json.message === "Password Incorrect") {
                    props.showAlert('Incorrect Password', 'error');
                    setSpinner(false);
                }
                else {
                    await props.fetchData()
                    setSpinner(false);
                    props.showAlert('Uploaded the file successfully', 'success');
                    hidePasswordForm();
                    arrayCall();
                }
            }
            )

        }
    }
    const hidePasswordForm = () => {
        document.getElementById('password-form').style.display = 'none'
    }
    const allowDrop = (event) => {
        event.preventDefault();
    }
    const handleDrop = async (event) => {
        setSpinner(true)
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        const fileid = `${Date.now()}__${file.name}`

        const formData = new FormData();

        formData.append('files', file)
        formData.append('user_access_token', UplaodFile.user_access_token)
        formData.append('user_report_id', UplaodFile.user_report_id)
        formData.append('acc_id', props.acc_id)
        formData.append('user_email_id', UplaodFile.user_email_id)
        formData.append('file_id', fileid)
        try {
            const response = await fetch(`${URL}/upload-files`, {
                method: "POST",
                body: formData,
            });
            const json = await response.json();
            if (json.message === "File Uploaded!" && json.body !== null ) {
                await props.fetchData()
                setSpinner(false);
                props.showAlert('Uploaded file successfully', 'success');
                arrayCall();
            }
            else if(json.message === "File Uploaded!" && json.body === null){

                await props.fetchData()
                setSpinner(false);
                props.showAlert('Uploaded file successfully', 'success');
                arrayCall();
            }
            else if (json.message === "SCANNED_DOCUMENT") {
                        console.log('Came Scanned Document....')
                        setSpinner(false);
                        const defaultData = {
                        title: 'Uploaded file is a scanned document.',
                        description: '(To process this scanned document, you need to upgrade to premium plus membership)',
                        link: 'https://proanalyser.in/more-info-scanned-documents/',
                        icon: 'fa-image'
                        };
                        setApiResponse(defaultData)
                        props.spin(false);
                        setShowPlanUpgrad(true);
                    }else if (json.message === "ENCRYPTED_PDF") {
                        console.log('Came Encrpted Document....')
                        const defaultData = {
                        title: 'Uploaded file is a encrypted document.',
                        description: '(To process this encrypted document, you need to upgrade to premium plus membership)',
                        link: 'https://proanalyser.in/more-info-encrypted-documents/',
                        icon: 'fa-lock'
                        };
                        setApiResponse(defaultData)
                         setSpinner(false);
                         props.spin(false)
                        setShowPlanUpgrad(true);
                    }
                     else if (json.message === "ACCOUNT_NUMBER_MISMATCH") {
                        setSpinner(false)
                        props.spin(false)
                        const defaultData = {
                        title: 'Uploaded file account number does not match with already uploaded file.',
                        description: '(To process multiple bank accounts you need to click "Add New Account" button below and upload this file there.)',
                        link: 'https://proanalyser.in/more-info-proanlyser-webapp/',
                        icon: 'fa-triangle-exclamation'
                        };
                        setApiResponse(defaultData)
                        setShowPlanUpgrad(true);
                        //props.showAlert('Account number mismatch. Make sure to upload correct bank statements to correct account', 'error');  
                    }
                     else if (json.message === "UNSUPPRTED_FORMAT") {
                        setSpinner(false)
                        props.spin(false)
                        const defaultData = {
                        title: 'Uploaded document is in unsupported format',
                        description: '(Right now we support PDF,EXCEL,CSV formats)',
                        link: 'https://proanalyser.in/more-info-proanlyser-webapp/',
                        icon: 'fa-triangle-exclamation'
                        };
                        setApiResponse(defaultData)
                        setShowPlanUpgrad(true);
                        
                        //props.showAlert('Account number mismatch. Make sure to upload correct bank statements to correct account', 'error');  
                    }
            else if (json.message === "Password Needed") {
                const jsonObject = JSON.parse(json.body);
                let pdfacc_id = jsonObject.acc_id;
                let file_path = jsonObject.file_path;
                let file_name = jsonObject.file_name;
                setSpinner(false)
                showPasswordForm(pdfacc_id, file_path, file_name);
            }
            if (json.message && json.message.code && (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException')){
                    setSpinner(false);
                    //showAlert("Session expired. Please log in again to continue...", 'error')
                    localStorage.clear();
                    navigate('/login');
                    return;
                }
        }
        catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const formatNumberWithCommas = (value) => {
        const numericValue = value.replace(/,/g, ''); // Remove existing commas
        var formattedValue;
        if (/^-?\d*\.?\d*$/.test(numericValue) || numericValue === '') {
            formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Format with commas
        }
        else {
            formattedValue = ''
        }
        return formattedValue;
    };
    const onAmountChange = (e) => {
        const rawValue = e.target.value;
        const numericValue = rawValue.replace(/,/g, '');
        setBankDetais({ ...bankDetails, ['od']: numericValue })
        const formattedValue = formatNumberWithCommas(rawValue);
        setAmount(formattedValue)
    }
    return (
        <>
            <div className="home-page-upload">
                {spinner && <Loader />}
                <form>
                    <div className="right-upload">
                        <div className="right-upload-heading">
                            <h1><i className="fa-solid fa-building-columns"></i>Bank Account</h1>
                            <div className="icons">
                                <img src={require("./Image/delete.png")} alt="" style={{ display: showdlticon }} onClick={dltAccount} />
                            </div>
                        </div>
                        <div className="right-upload-content ">
                            <div className="bank-deatils">
                                <div className="row-input">
                                    <div className="bank-name">
                                        <select disabled={spinner} required id="name" value={bankDetails.name} name="name" onChange={onChange} >
                                            <option value="null"  >Select</option>
                                            <option value="Airtel Payments Bank">Airtel Payments Bank</option>
                                            <option value="AU Small Finance Bank">AU Small Finance Bank</option>
                                            <option value="Axis Bank">Axis Bank</option>
                                            <option value="Bandhan Bank">Bandhan Bank</option>
                                            <option value="Bank of Baroda">Bank of Baroda</option>
                                            <option value="Bank of India">Bank of India</option>
                                            <option value="Bank of Maharashtra">Bank of Maharashtra</option>
                                            <option value="Barclays Bank">Barclays Bank</option>
                                            <option value="Canara Bank">Canara Bank</option>
                                            <option value="Capital small finance bank">Capital small finance bank</option>
                                            <option value="Central Bank of India">Central Bank of India</option>
                                            <option value="Citi Bank">Citi Bank</option>
                                            <option value="City Union Bank">City Union Bank</option>
                                            <option value="CSB Bank">CSB Bank</option>
                                            <option value="DBS Bank">DBS Bank</option>
                                            <option value="DCB Bank">DCB Bank</option>
                                            <option value="Deutsche Bank">Deutsche Bank</option>
                                            <option value="Dhanlaxmi Bank">Dhanlaxmi Bank</option>
                                            <option value="Equitas Small Finance Bank">Equitas Small Finance Bank</option>
                                            <option value="ESAF Bank">ESAF Bank</option>
                                            <option value="FINO Payments Bank">FINO Payments Bank</option>
                                            <option value="Fincare Small Finance Bank">Fincare Small Finance Bank</option>
                                            <option value="Federal Bank">Federal Bank</option>
                                            <option value="HDFC Bank">HDFC Bank</option>
                                            <option value="HSBC Bank">HSBC Bank</option>
                                            <option value="ICICI Bank">ICICI Bank</option>
                                            <option value="IDBI Bank">IDBI Bank</option>
                                            <option value="IDFC Bank">IDFC Bank</option>
                                            <option value="Indian Bank">Indian Bank</option>
                                            <option value="Indian Overseas Bank">Indian Overseas Bank</option>
                                            <option value="India Post Payments Bank">India Post Paments Bank</option>
                                            <option value="IndusInd Bank">IndusInd Bank</option>
                                            <option value="Jammu and Kashmir Bank">Jammu and Kashmir Bank</option>
                                            <option value="Jana Small Finance Bank">Jana Small Finance Bank</option>
                                            <option value="Jio Payments Bank">Jio Payments Bank</option>
                                            <option value="Karnataka Bank">Karnataka Bank</option>
                                            <option value="Karur Vysya Bank">Karur Vysya Bank</option>
                                            <option value="Kotak Mahindra Bank">Kotak Mahindra Bank</option>
                                            <option value="Nainital Bank">Nainital Bank</option>
                                            <option value="NSDL Payments Bank">NSDL Paments Bank</option>
                                            <option value="North East Smaill Finance Bank">North East Smaill Finance Bank</option>
                                            <option value="Oriental Bank of Commerce">Oriental Bank of Commerce</option>
                                            <option value="Punjab National Bank">Punjab National Bank</option>
                                            <option value="Punjab and Sind Bank">Punjab and Sind Bank</option>
                                            <option value="Paytm Payments Bank">Paytm Paments Bank</option>
                                            <option value="RBL Bank">RBL Bank</option>
                                            <option value="South Indian Bank">South Indian Bank</option>
                                            <option value="Standard Chartered Bank">Standard Chartered Bank</option>
                                            <option value="Shivalik Small Finance Bank">Shivalik Small Finance Bank</option>
                                            <option value="State Bank of India">State Bank of India</option>
                                            <option value="Suryoday Small Finance Bank">Suryoday Small Finance Bank</option>
                                            <option value="Tamilnad Mercantile Bank">Tamilnad Mercantile Bank</option>
                                            <option value="UCO Bank">UCO Bank</option>
                                            <option value="Union Bank of India">Union Bank of India</option>
                                            <option value="Ujjivan Small Finance Bank">Ujjivan Small Finance Bank</option>
                                            <option value="Utkarsh Small Finance Bank">Utkarsh Small Finance Bank</option>
                                            <option value="Unity Small Finance Bank">Unity Small Finance Bank</option>
                                            <option value="Yes Bank">Yes Bank</option>
                                            <option value="Other Regional Rural Bank">Other Regional Rural Bank</option>
                                            <option value="Other Cooperative Bank">Other Cooperative Bank</option>
                                            <option value="Other Financial Institutions">Other Financial Institutions</option>
                                            <option value="Other Banks">Other Banks</option>
                                        </select>
                                        <div className="label">Bank Name <span className='required'>*</span></div>
                                    </div>
                                </div>

                                <div className="row-input">
                                    <div className="account-type">
                                        <select disabled={spinner} required id="type" value={bankDetails.type} name="type" onChange={onChange}>
                                            <option value="null" >Select</option>
                                            <option value="Savings">Savings</option>
                                            <option value="Current">Current</option>
                                            <option value="Overdraft">Overdraft</option>
                                            <option value="Cashcredit">Cash Credit Account</option>
                                        </select>
                                        <div className="label">Account Type <span className='required'> *</span></div>
                                    </div>
                                    <div className="account-number">
                                        <input required disabled={spinner} autoComplete='off' type="number" value={bankDetails.number} accept="number" name="number" id="number" minLength="16" maxLength="16" onChange={onChange} />
                                        <div className="label">Account Number <span className='required'> *</span></div>

                                    </div>
                                </div>
                                <div className="row-input sanction-limit">
                                    <p>Sanction limit</p>
                                    <hr />
                                    <div className="saction-limit">
                                        <input type="text" autoComplete='off' disabled={spinner} name="od" id="od" value={amount} required onChange={onAmountChange} />
                                        <div className="label">Amount</div>
                                    </div>
                                </div>
                                <div className="row-input">
                                    <div className="analysis-date-from">
                                        <DatePicker value={bankDetails.analysis_from} onChange={onDateAnaFromChange} dateFormat="dd/MM/yyyy" />
                                        <div className="label">Analysis From</div>
                                        {bankDetails.analysis_from && <div className="cleardate" onClick={() => {
                                            setBankDetais({ ...bankDetails, ['analysis_from']: '' })
                                        }}>&#x2715;</div>}
                                    </div>
                                    <div className="analyze-date-to">
                                        <DatePicker value={bankDetails.analysis_to} onChange={onDateAnaToChange} dateFormat="dd/MM/yyyy" />
                                        <div className="label">Analysis to</div>
                                        {bankDetails.analysis_to && <div className="cleardate" onClick={() => {
                                            setBankDetais({ ...bankDetails, ['analysis_to']: '' })
                                        }}>&#x2715;</div>}

                                    </div>
                                </div>

                            </div>
                            <div className="new-report">
                                <div className="input-statement" id="drop-zone" onDrop={handleDrop} onDragOver={allowDrop}>
                                    <div className="uplaod-file-img">
                                        <label htmlFor="statment"><img src={require("./Image/upload file.png")} alt="" /></label>
                                    </div>
                                    <div className="uplaod-file-text">
                                        <p className="head-upload">To upload drop bank statments or <a href='/'> <label htmlFor={props.acc_id}> Browse</label></a></p>
                                        <input type="file" name="files" disabled={spinner} accept=".pdf, .xls, .xlsx, .csv" id={props.acc_id} onChange={uplaodedChange} />
                                    </div>
                                </div>
                                <div className="statements">
                                    <div className="table">
                                        <div className="table-heading">
                                            <div className="heading">File Name</div>
                                            <div className="heading heading-status">Status</div>
                                            <div className="heading" ></div>
                                        </div>
                                        <hr />
                                        <div className="table-body">
                                            {uplaodedFile && uplaodedFile.map((e, index) => {

                                                let displayStatus = e.file_status;
                                                if (e.file_status === 'OCR_NEEDED') {
                                                    displayStatus = 'Scanned document';
                                                }
                                                else if (e.file_status === 'ENCRYPTED_PDF') {
                                                    displayStatus = 'Encrypted document';
                                                }else{
                                                     displayStatus = 'Ready to Process';
                                                }
                                                 let logoSrc = require('./Image/document.png'); 
                                                 if (e.file_type === 'EXCEL') {
                                                logoSrc = require('./Image/excel_icon.png');
                                                 }else if (e.file_type === 'CSV'){
                                                logoSrc = require('./Image/csv.png');
                                                 }
                                                return <div className="row" key={index}>
                                                    <div className="table-data">
                                                        <div className="data data-name"><img src={logoSrc} alt="" /><a target='_singh' href={e.file_path}>{e.file_name}</a></div>
                                                        <div className="data data-status"><a href="/">{displayStatus}</a></div>
                                                        <div className="data data-delete" onClick={() => {
                                                            dltFile(e.file_id)
                                                        }}><i className="fa-solid fa-trash-can"></i></div>

                                                    </div>
                                                    <hr />
                                                </div>
                                            })
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </form>
            </div>
            <div className="password-Input" id="password-form">
                <div className="password-Input-Heading">
                    <h1>Enter Password</h1>
                    <i className="fa-solid fa-xmark" id='closeForm' onClick={() => {
                        hidePasswordForm()
                    }}></i>
                </div>
                <form id="password-form" >
                    <label htmlFor="pswd">Password</label>
                    <input type="text" name='pswd' id='pswd' />
                    <div className="form-btn">
                        <button disabled={spinner} type='submit'>Check Password</button>
                        <button disabled={spinner} type='reset' onClick={() => {
                            hidePasswordForm()
                        }}>Cancel</button>
                    </div>
                </form>
            </div>
        {showPlanUpgrad && <PlanUpgrad setShowPlanUpgrad={setShowPlanUpgrad} additionalData={apiResponse}/>} </>
    )
}
export default NewReportHomePage
