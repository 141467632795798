import React from 'react';

const PlanUpgrad = ({ setShowPlanUpgrad,additionalData }) => {
  console.log('Came to the Upgrad popup');

  return (
    <div className='subscription-over'>
<div className='icon-and-text'>
  <i className={`fa-solid ${additionalData.icon}  fa-2x`} style={{ display: 'inline-block', marginRight: '8px' }}></i>
  <h3 style={{ display: 'inline-block', verticalAlign: 'middle' }}>{additionalData.title}</h3>
</div>
      <div className='warning-gap'></div>
     
      <div className='warning-gap'></div>

      <p className='warning-message'>
        {additionalData.description}
        
      </p>

      <div className='know-more-link'>
  <a href={additionalData.link} style={{ color: 'blue' }} target='_blank' rel='noopener noreferrer'>
    Know More
  </a>
</div>

<div className='warning-gap'></div>

      <button onClick={() => setShowPlanUpgrad(false)}>Okay</button>
    </div>
  );
}

export default PlanUpgrad;
