import React from 'react'

const Loader = () => {
  return (
    <div className="loader">
      <p>Please Wait...</p>
    </div>
  )
}

export default Loader
