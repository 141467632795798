import React, { useState } from 'react'
import './Login.css'
import { useNavigate, Link  } from 'react-router-dom';
import Loader from './Loader';

const Confirmationpage = (props) => {
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const URL = window.globalURL

  const [Auth, setAuth] = useState({
    email: localStorage.getItem('user_email'),
    confirmationCode: '',
  })

  const onChange = (e) => {
    setAuth({ ...Auth, [e.target.name]: e.target.value })
  }

  const submitForm = async (e) => {
    setSpinner(true);
    e.preventDefault();

    // calling the confirm API
try{
    const response = await fetch(`${URL}/confirm`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: Auth.email, confirmationCode: Auth.confirmationCode }),

    });
    const json = await response.json();

    // Giving screen as per the response

    if (json.message === 'Confirmation successful') {
      props.showAlert("Account created successfully", 'success')
      navigate('/login')
      setSpinner(false);
    }
    else {
      setSpinner(false);
      props.showAlert("Invalid Code", 'success')
    }
  }
  catch (error) {
    console.log(error);
    console.log(error.message, error);
  }
  }
  return (
    <div>
      <div className="auth-wrapper">
        {spinner && <Loader />}
        <div className="auth-inner">

          {/* Form of entering the confirmationCode */}

          <form onSubmit={submitForm}>
            <h3>Confirmation</h3>
            <div className="mb-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                value={Auth.email}
                onChange={onChange}
                name='email'
                id='email'
                readOnly 
              />
            </div>
            
            <div className="mb-3">
              <label>Confirmation Code</label>
              <input
                type="text"
                className="form-control"
                value={Auth.confirmationCode}
                onChange={onChange}
                required
                name='confirmationCode'
                id='confirmationCode'
              />
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form></div></div></div>
  )
}

export default Confirmationpage
