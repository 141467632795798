import React from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css';
const MonthlySummary = (props) => {
  const {realdata, value} = props
    // It is for AG grid Column Data

    var columns = realdata[value] && realdata[value].length > 0
    ? realdata[value][1].map((header, index) => ({
      field: `col${index}`,
      headerName: header,
      width:150,
      valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
    }))
    : [];

  // It is for AG grid Row Data

  const rows = realdata[value] && realdata[value].length > 1
    ? realdata[value].slice(2).map((row, index) => {
      const rowData = { id: index };
      row.forEach((cell = 'none', cellIndex) => {
        rowData[`col${cellIndex}`] = cell || 0;
      });
      return rowData;
    })
    : [];

    // Applicable for all Column

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const rowStyle = {
    fontSize: '0.8em',
  }
  return (
    <div className="ag-theme-alpine"  >
    <AgGridReact
      rowData={rows} // Row Data for Rows
      columnDefs={columns} // Column Defs for Columns
      animateRows={true} // Optional - set to 'true' to have rows animate when sorted
      defaultColDef={defaultColDef}
      rowStyle={rowStyle}

    />
  </div>
  )
}

export default MonthlySummary
