import React, { useEffect, useState } from 'react'
import Reports from './component/Reports'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ExcelReader from './component/ExcelReader'
import Navbar from './component/Navbar'
import SignUp from './component/SignUp';
import Login from './component/Login';
import Confirmationpage from './component/ConfirmationPage';
import ForgetPassword from './component/ForgetPassword';
import Alert from './component/Alert';
import ConfirmForgotPassword from './component/ConfirmForgotPassword';
import HomePage from './component/HomePage';
import UplaodFile from './component/UplaodFile';
import ExcelDataGrid from './component/excelComponent/ExcelComponent';
import UplaodedReport from './component/UplaodedReport';
import TeamReports from './component/TeamReports';
import './component/css/Responsive.css';
import Loader from './component/Loader';

const App = () => {
  ///window.globalURL = 'http://16.16.197.180:2000/api';

  window.globalURL = 'https://web.proanalyser.in/api';
  
  //window.globalURL = 'http://localhost:2000/api';
  
  
  const [spinner, setSpinner] = useState(true);
  const [data, setData] = useState({});
  const [randomNumber, setRandomNumber] = useState('');

  const data1 = (data) => {
    setData(data);
  }

  const [alert, setAlert] = useState(null)
  const showAlert = (msg, type) => {
    setAlert({
      msg: msg,
      type: type
    })
    setTimeout(() => {
      setAlert(null)
    }, 6000);
  }

  useEffect(() => {
    const twentyFourHours = 24 * 60 * 60 * 1000;
    setTimeout(() => {
      localStorage.clear();
    }, twentyFourHours)
    const min = 100000; 
    const max = 999999; 
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    setRandomNumber(randomNum);
    setSpinner(false);

    return () => {
      setSpinner(true);
    };

  }, [])
  return (
    <div>
      <BrowserRouter>
        <Navbar showAlert={showAlert} />
        <Alert alert={alert} />
        <Routes>
          <Route>
            <Route exact path='/' element={<Reports data={data1} />} />
            <Route exact path='/home' element={<HomePage />} />
            <Route exact path='/reports' element={<Reports data={data1} />} />
            <Route exact path='/upload-file' element={<UplaodFile showAlert={showAlert} />} />
            <Route exact path='/sign-up' element={<SignUp showAlert={showAlert} />} />
            <Route exact path='/login' element={<Login showAlert={showAlert} />} />
            <Route exact path='/confirmation' element={<Confirmationpage showAlert={showAlert} />} />
            <Route exact path='/forget-password' element={<ForgetPassword showAlert={showAlert} />} />
            <Route exact path='/confirm-forget-password' element={<ConfirmForgotPassword showAlert={showAlert} />} />
            <Route exact path="/excel" element={<ExcelReader data={data} />} />
            <Route exact path="/excel-component" element={<ExcelDataGrid />} />
            <Route exact path="/uploaded-report" element={<UplaodedReport />} />
            <Route exact path="/team-report" element={<TeamReports  data={data1}/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}
export default App