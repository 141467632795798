import React from 'react'

const ComingSoonPopUp = (props) => {
  return (
    <div className='comming-soon'>
      <h1>Comming Soon...</h1>
      <button onClick={()=>{
        props.comingpopup(false)
      }}>Okay</button>
    </div>
  )
}

export default ComingSoonPopUp
