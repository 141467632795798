import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UserDataPopUp from './UserDataPopUp';

const Navbar = (props) => {
    const navigate = useNavigate();
    const [showDataPopUp, setshowDataPopUp] = useState(false);
    const hidenavbar = () => {
        document.getElementById('show-hide-nav').style.display = 'none'
        document.getElementById('close').style.display = 'none'
        document.getElementById('hamberger').style.display = 'block'
    }
    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            navigate('/login')
        }
    }, [])
    return (
        <>
            <div className='navbar'>
                <div className="nav-left" id='logo'>
                    Pro Analyser
                </div>
                <div className="nav-center">

                </div>
                <div className="nav-right" id='show-hide-nav'>
                    {
                        !localStorage.getItem('accessToken') ?
                            <>
                                <Link to='/login' onClick={()=>{ window.innerWidth < 700 && hidenavbar()}}><button>Login</button></Link>
                                <Link to='/sign-up' onClick={()=>{ window.innerWidth < 700 && hidenavbar()}}><button>Sign Up</button></Link>
                            </> :
                            <><nav>
                                <ul>
                                    <li><Link to='/reports' onClick={()=>{ window.innerWidth < 700 && hidenavbar()}}><i className="fa-regular fa-file"></i>Reports</Link></li>
                                    <li><Link to='/home' onClick={()=>{ window.innerWidth < 700 && hidenavbar()}}><i className="fa-solid fa-house"></i>Details</Link></li>
                                    <li><Link onClick={() => { 
                                        setshowDataPopUp(true)
                                        window.innerWidth < 700 && hidenavbar()
                                        }} ><i className="fa-solid fa-user" ></i>My Account</Link></li>
                                    <li><Link to ='https://proanalyser.in/webapp-usuage-tutorials/' target='_blank' ><i className="fa-solid fa-circle-info"></i>Help</Link></li>
                                </ul>
                            </nav>
                                <Link to='/login' onClick={() => {
                                    localStorage.clear()
                                    props.showAlert("Successfully logged out", 'success')
                                    window.innerWidth < 700 && hidenavbar()
                                }} ><button><i className="fa-solid fa-right-from-bracket"></i>Log out</button></Link>
                            </>

                    }

                </div>
                <div className="nav-responsive">
                    <i className="fa-solid fa-bars" id='hamberger' onClick={() => {
                        document.getElementById('show-hide-nav').style.display = 'block'
                        document.getElementById('close').style.display = 'flex'
                        document.getElementById('hamberger').style.display = 'none'
                    }}></i>
                    <i className="fa-solid fa-xmark" id='close' onClick={hidenavbar}></i>
                </div>
            </div>
            {showDataPopUp && <UserDataPopUp showDataPopUp={setshowDataPopUp} />}
        </>
    )
}

export default Navbar
