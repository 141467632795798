import React from 'react'
import { Link } from 'react-router-dom'

const SubscriptionOver = () => {
  return (
    <div className='subscription-over'>
      <h4>Your subscription is over. Talk to our team for the next process. Contact No. +91 9342833052</h4>
      <Link to='/home'><button >Okay</button></Link>
    </div>
  )
}

export default SubscriptionOver
