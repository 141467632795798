import React from 'react'
import { Link } from 'react-router-dom'

const ReportCheck = (props) => {
    const objectEntries = Object.entries(props.reportDetails.Uploaded);
    console.log('-------------------')
    //console.log(props.reportDetails.Uploaded) 
    console.log(props.reportDetails.Uploaded.MainAcc)
    
    return (
        <div>
            <div className="report-check">
                <div className="report-check-heading">
                    <h1>{props.reportDetails.report_name}</h1>
                    <h6>({props.reportDetails.report_ref_id})</h6>
                    <div className="uploaded-file-buttons">
                    <Link to='/uploaded-report'><button><i className="fa-solid fa-file-lines"></i>Uploaded files</button></Link>
                    </div>
                </div>
                <div className="report-check-bank-details">
                    {
                        objectEntries.map(([key, value]) => (

                            <div key={key} className="each-bank-details">
                                        <div className="each-bank-name">
                                             <i className="fa-solid fa-building-columns" />
                                             {`${value.bank_name} - ${value.acc_no.slice(-4)}`} 
                                         </div>
                                           <div className={`account-status-for-res-pop ${value.acc_status === 'Processing...' ? 'processing' : (value.acc_status === 'Report Ready' ? 'ready' : (value.acc_status === 'Process on Hold' ?'onhold':''))}`}>
                                                        {`${value.acc_status}`} 
                                                    </div>
                                            <div className="details-download">
                                            {
                                            (value.acc_status === 'Report Ready' && value.acc_result !== 'null') ? (
                                                <Link to={value.acc_result}>
                                                <button><i className="fa-solid fa-download"></i>Download</button>
                                                </Link>
                                            ) : (
                                                <button disabled style={{ opacity: 0.5 }}><i className="fa-solid fa-download"></i>Download</button>
                                            )
                                            }
                                        </div>
                                    </div>
                          ))
                    }

                    {  
                    objectEntries.length > 1&& (
                        
                        <div className="each-bank-details">
                            <div className="each-bank-name">
                                <i className="fa-solid fa-building-columns" />
                                {'All Accounts'}
                            </div>
                            <div className={`account-status-for-res-pop ${props.reportDetails.Uploaded.MainAcc.all_account_result !== 'null' ? 'ready' : 'processing'}`}>
                                                        {`${props.reportDetails.Uploaded.MainAcc.all_account_result}` !== 'null' ? 'Report Ready': '-----'} 
                                                    </div>
                            <div className="details-download">
                                {
                                      (props.reportDetails.Uploaded.MainAcc.all_account_result !== 'null') ? (
                                                <Link to={props.reportDetails.Uploaded.MainAcc.all_account_result}>
                                                <button><i className="fa-solid fa-download"></i>Download</button>
                                                </Link>
                                            ) : (
                                                <button disabled style={{ opacity: 0.5 }}><i className="fa-solid fa-download"></i>Download</button>
                                            )
                                }
                            </div>
                        </div>
                    )}

                </div>
                <div className="check-buttons">
                    <div className="close-report-pop-up"><button onClick={() => window.location.reload()}>Refresh</button></div>
                    <div className="close-report-pop-up"><Link onClick={()=>{props.setReportCheck(false)}}><button>Close</button></Link></div>
                </div>
            </div>
        </div>
    )
}

export default ReportCheck
