import React, { useEffect, useState } from 'react'
import Loader from './Loader'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
const UplaodedReportHomepage = (props) => {
    const [fileName, setFileName] = useState([]);
    const [spinner, setSpinner] = useState(false)
    const [bankDetails, setBankDetais] = useState({
        acc_id: props.acc_id,
        name: props.bankDetails.bank_name,
        type: props.bankDetails.acc_type,
        number: props.bankDetails.acc_no,
        od: props.bankDetails.od_limit,
        analysis_from: props.bankDetails.ana_from === 'null' ? '' : props.bankDetails.ana_from,
        analysis_to: props.bankDetails.ana_to === 'null' ? '' : props.bankDetails.ana_to
    })
    const [uplaodedFile, setUplaodedFile] = useState([]);

    const arrayCall = () => {
        fileName.length = 0;
        for (const accountName in props.bankDetails.files) {
            if (accountName.endsWith("__FileID") || accountName.endsWith("__FilesID")) {
                if (!fileName.includes(props.bankDetails.files[accountName])) {
                    fileName.push(props.bankDetails.files[accountName])
                }
            }

        }
        setUplaodedFile(fileName)
    }
    useEffect(() => {
        arrayCall();
    })
    return (
        <>
            <div className="home-page-upload">
                {spinner && <Loader />}
                <form >
                    <div className="right-upload">
                        <div className="right-upload-heading">
                            <h1><i className="fa-solid fa-building-columns"></i> Bank Account</h1>
                        </div>
                        <div className="right-upload-content ">
                            <div className="bank-deatils">
                                <h3>Bank Account Details</h3>
                                <hr />
                                <div className="row-input">
                                    <div className="bank-name">
                                        <select disabled className='disabledbutton' id="name" value={bankDetails.name} name="name" >
                                            <option value="null"  >Select</option>
                                            <option value="Airtel Payments Bank">Airtel Payments Bank</option>
                                            <option value="AU Small Finance Bank">AU Small Finance Bank</option>
                                            <option value="Axis Bank">Axis Bank</option>
                                            <option value="Bandhan Bank">Bandhan Bank</option>
                                            <option value="Bank of Baroda">Bank of Baroda</option>
                                            <option value="Bank of India">Bank of India</option>
                                            <option value="Bank of Maharashtra">Bank of Maharashtra</option>
                                            <option value="Barclays Bank">Barclays Bank</option>
                                            <option value="Canara Bank">Canara Bank</option>
                                            <option value="Capital small finance bank">Capital small finance bank</option>
                                            <option value="Central Bank of India">Central Bank of India</option>
                                            <option value="Citi Bank">Citi Bank</option>
                                            <option value="City Union Bank">City Union Bank</option>
                                            <option value="CSB Bank">CSB Bank</option>
                                            <option value="DBS Bank">DBS Bank</option>
                                            <option value="DCB Bank">DCB Bank</option>
                                            <option value="Deutsche Bank">Deutsche Bank</option>
                                            <option value="Dhanlaxmi Bank">Dhanlaxmi Bank</option>
                                            <option value="Equitas Small Finance Bank">Equitas Small Finance Bank</option>
                                            <option value="ESAF Bank">ESAF Bank</option>
                                            <option value="FINO Payments Bank">FINO Payments Bank</option>
                                            <option value="Fincare Small Finance Bank">Fincare Small Finance Bank</option>
                                            <option value="Federal Bank">Federal Bank</option>
                                            <option value="HDFC Bank">HDFC Bank</option>
                                            <option value="HSBC Bank">HSBC Bank</option>
                                            <option value="ICICI Bank">ICICI Bank</option>
                                            <option value="IDBI Bank">IDBI Bank</option>
                                            <option value="IDFC Bank">IDFC Bank</option>
                                            <option value="Indian Bank">Indian Bank</option>
                                            <option value="Indian Overseas Bank">Indian Overseas Bank</option>
                                            <option value="India Post Payments Bank">India Post Paments Bank</option>
                                            <option value="IndusInd Bank">IndusInd Bank</option>
                                            <option value="Jammu and Kashmir Bank">Jammu and Kashmir Bank</option>
                                            <option value="Jana Small Finance Bank">Jana Small Finance Bank</option>
                                            <option value="Jio Payments Bank">Jio Payments Bank</option>
                                            <option value="Karnataka Bank">Karnataka Bank</option>
                                            <option value="Karur Vysya Bank">Karur Vysya Bank</option>
                                            <option value="Kotak Mahindra Bank">Kotak Mahindra Bank</option>
                                            <option value="Nainital Bank">Nainital Bank</option>
                                            <option value="NSDL Payments Bank">NSDL Paments Bank</option>
                                            <option value="North East Smaill Finance Bank">North East Smaill Finance Bank</option>
                                            <option value="Oriental Bank of Commerce">Oriental Bank of Commerce</option>
                                            <option value="Punjab National Bank">Punjab National Bank</option>
                                            <option value="Punjab and Sind Bank">Punjab and Sind Bank</option>
                                            <option value="Paytm Payments Bank">Paytm Paments Bank</option>
                                            <option value="RBL Bank">RBL Bank</option>
                                            <option value="South Indian Bank">South Indian Bank</option>
                                            <option value="Standard Chartered Bank">Standard Chartered Bank</option>
                                            <option value="Shivalik Small Finance Bank">Shivalik Small Finance Bank</option>
                                            <option value="State Bank of India">State Bank of India</option>
                                            <option value="Suryoday Small Finance Bank">Suryoday Small Finance Bank</option>
                                            <option value="Tamilnad Mercantile Bank">Tamilnad Mercantile Bank</option>
                                            <option value="UCO Bank">UCO Bank</option>
                                            <option value="Union Bank of India">Union Bank of India</option>
                                            <option value="Ujjivan Small Finance Bank">Ujjivan Small Finance Bank</option>
                                            <option value="Utkarsh Small Finance Bank">Utkarsh Small Finance Bank</option>
                                            <option value="Unity Small Finance Bank">Unity Small Finance Bank</option>
                                            <option value="Yes Bank">Yes Bank</option>
                                            <option value="Other Regional Rural Bank">Other Regional Rural Bank</option>
                                            <option value="Other Cooperative Bank">Other Cooperative Bank</option>
                                            <option value="Other Financial Institutions">Other Financial Institutions</option>
                                            <option value="Other Banks">Other Banks</option>
                                        </select>
                                        <div className="label">Bank Name <span className='required'>*</span></div>
                                    </div>
                                </div>

                                <div className="row-input">
                                    <div className="account-type">
                                        <select disabled className='disabledbutton' id="type" value={bankDetails.type} name="type">
                                            <option value="Savings">Savings</option>
                                            <option value="Current">Current</option>
                                            <option value="Overdraft">Overdraft</option>
                                            <option value="Cashcredit">Cash Credit Account</option>
                                        </select>
                                        <div className="label">Account Type <span className='required'> *</span></div>
                                    </div>
                                    <div className="account-number">
                                        <input disabled className='disabledbutton' type="number" value={bankDetails.number} accept="number" name="number" id="number" minLength="16" maxLength="16" />
                                        <div className="label">Account Number <span className='required'> *</span></div>

                                    </div>
                                </div>
                                <div className="row-input sanction-limit">
                                    <p>Sanction limit</p>
                                    <hr />
                                    <div className="saction-limit">
                                        <input disabled className='disabledbutton' type="number" name="od" id="od" value={bankDetails.od} />
                                        <div className="label">Amount</div>
                                    </div>
                                </div>
                                <div className="row-input">
                                    <div className="analysis-date-from">
                                        <DatePicker disabled value={bankDetails.analysis_from} className='disabledbutton' dateFormat="dd/MM/yyyy" />
                                        <div className="label">Analysis From</div>
                                    </div>
                                    <div className="analyze-date-to">
                                        <DatePicker disabled className='disabledbutton' value={bankDetails.analysis_to} dateFormat="dd/MM/yyyy" />
                                        <div className="label">Analysis to</div>
                                    </div>
                                </div>

                            </div>
                            <div className="new-report">
                                <h2>Bank Statement</h2>
                                <div className="input-statement">
                                    <div className="uplaod-file-img">
                                        <label htmlFor="statment"><img src={require("./Image/upload file.png")} alt="" /></label>
                                    </div>
                                    <div className="uplaod-file-text">
                                        <p className="head-upload">Uploaded Bank Statements</p>
                                        <p className="sub-head-upload" > Click file name to download uploaded statments</p>
                                        <input disabled className='disabledbutton' type="file" name="files" accept=".pdf" id={props.acc_id} />
                                    </div>
                                </div>
                                <div className="statements">
                                    <div className="table">
                                        <div className="table-heading">
                                            <div className="heading">File Name</div>
                                            <div className="heading heading-status">Status</div>
                                            <div className="heading"></div>
                                        </div>
                                        <hr />
                                        <div className="table-body">
                                            {uplaodedFile && uplaodedFile.map((e, index) => {

                                               let displayStatus = e.file_status;

                                                if (e.file_status === 'OCR_NEEDED') {
                                                    displayStatus = 'Scanned document';
                                                }
                                                else if (e.file_status === 'ENCRYPTED_PDF') {
                                                    displayStatus = 'Encrypted document';
                                                }else{
                                                     displayStatus = 'Ready to Process';
                                                }
                                                 let logoSrc = require('./Image/document.png'); 
                                                 if (e.file_type === 'EXCEL') {
                                                logoSrc = require('./Image/excel_icon.png');
                                                }

                                                return <div className="row" key={index}>
                                                    <div className="table-data">
                                                        <div className="data data-name"><img src={logoSrc} alt="" /><a target='_singh' href={e.file_path}>{e.file_name}</a></div>
                                                        <div className="data data-status"><a href="/">{displayStatus}</a></div>
                                                        <div className="data data-delete"><img src={require("./Image/delete.png")} alt="" /></div>

                                                    </div>
                                                    <hr />
                                                </div>
                                                })
                                            }


                                        </div>
                                    </div>
                                </div>
                                 </div>
                        </div>
                    </div>


                </form>
            </div>
        </>
    )
}
export default UplaodedReportHomepage
