import React from 'react'
import { Link } from 'react-router-dom'

const ReportCreatedPopUp = () => {
    return (
        <div>
            <div className="report-created">
                <h1>Process started successfully...</h1>
                <p>Analysis may take 1 to 5 minutes depending on number of transactions</p>
                <div className="level-report">
                    <div className="level-one-report">
                    <p>1</p > 
                    <span>Parsing</span>
                    </div>
                    <hr />
                    <div className="level-two-report"> 
                    <p>2</p >
                    <span>Bank Account Analysis</span>
                    </div>
                </div>
                <span><i className="fa-regular fa-clock"></i>Parsing 1 of 1 Bank Account</span>
                <div className="close-report-pop-up"><Link to="/reports"><button>CLOSE</button></Link></div>
            </div>
        </div>
    )
}

export default ReportCreatedPopUp
