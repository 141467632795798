import React from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css';
const RecurringCredits = (props) => {
  const {realdata, value} = props

    var columns = [
        {
            field: `col0`,
            headerName: "Start Date",
            width: 130,
            pinned: 'left',
        },
        {
            field: `col1`,
            headerName: "End Date",
            width:130
        },
        {
            field: `col2`,
            headerName: "Amount",
            filter: 'agNumberColumnFilter',
              width:140,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col3`,
            headerName: "Counter party",
            filter: 'agNumberColumnFilter',
              width:350,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col4`,
            headerName: "Txn Count",
            filter: 'agNumberColumnFilter',
              width:150,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col5`,
            headerName: "Interval",
              width:120,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col6`,
            headerName: "Misses",
              width:100,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        },
        {
            field: `col7`,
            headerName: "Tags",
              width:130,
            valueFormatter: params => {
        if (params.value === undefined) {
          return '0';
        }
        return params.value.toLocaleString('en-IN')
      } 
        }
    ]
    const rows = realdata[value] && realdata[value].length > 1
    ? realdata[value].slice(2).map((row, index) => {
      const rowData = { id: index };
      row.forEach((cell = 'none', cellIndex) => {
        rowData[`col${cellIndex}`] = cell || "0";
      });
      return rowData;
    })
    : [];
    const defaultColDef = React.useMemo(() => {
        return {
          resizable: true,
          filterParams: {
            buttons: ['reset', 'apply'],
            closeOnApply: true,
          },
          wrapText: true,
          autoHeight: true,
        };
      }, []);
    
      const rowStyle = {
        fontSize: '0.8em',
      }
  return (
    <div className="ag-theme-alpine"  >
    <AgGridReact
      rowData={rows} // Row Data for Rows
      columnDefs={columns} // Column Defs for Columns
      animateRows={true} // Optional - set to 'true' to have rows animate when sorted
      defaultColDef={defaultColDef}
      rowStyle={rowStyle}

    />
  </div>
  )
}

export default RecurringCredits
