import {React, useState, useMemo, useEffect} from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgChartsReact } from 'ag-charts-react';

const OverView = (props) => {
  const {realdata, value} = props
   const [creditDebitGraph, setCreditDebitGraph] = useState([])
  const [avgBalanceGraph, setAvgBalanceGraph] = useState([])
  const [netCashFlowGraph, setNetCashFlowGraph] = useState([])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const rowStyle = {
    fontSize: '0.8em',
  }



  
  const [options, setOptions] = useState({
    title: {
      text: "Monthly Credit & Debit",
    },
    data: creditDebitGraph,
    series: [
      {
        type: 'column',
        xKey: 'months',
        yKey: 'credit',
        fill:'green'
      },
      {
        type: 'column',
        xKey: 'months',
        yKey: 'debit'
      },
    ],
  });
  const [avgBalanceOptions, setAvgBalanceOptions] = useState({
    title: {
      text: "Monthly Avg Balance",
    },
    data: avgBalanceGraph,
    series: [
      {
        type: 'column',
        xKey: 'months',
        yKey: 'avgBalance'
      }
    ],
  });
  const [netCashFlowOptions, setNetCashFlowOptions] = useState({
    autoSize: true,
    title: {
      text: 'Monthly Net CashFlow',
    },
    data: netCashFlowGraph,
    series: [
      {
        xKey: 'months',
        yKey: 'netCashFlow',
      },
    ],
  });


  // For OverView Table

  var Overviewcolumns = [
    {
        field: `col0`,
        headerName: "Attribute name",
    },
    {
        field: `col1`,
        headerName: "Value",
        width:320
    }
]
  


// It is for AG grid Row Data

const OverViewRows = realdata[value][value] && realdata[value][value].length > 1
? realdata[value][value].slice(2).map((row, index) => {
  const rowData = { id: index };
  row.forEach((cell = 'none', cellIndex) => {
    rowData[`col${cellIndex}`] = cell || "none";
  });
  return rowData;
})
: [];



  useEffect(()=>{
    let exceldata = realdata[value]['Cashflow Summary']
    exceldata = exceldata.slice(2, exceldata.length -7)
    exceldata.map((e)=>{
      let content = e.slice(0,3)
     let myobject = {
      'months':content[0],
      'credit':parseInt(content[1]),
      'debit':parseInt(content[2])
     }
     creditDebitGraph.push(myobject)
    })
    exceldata.map((e)=>{
      let content = e.slice(0,5)
      let myobject = {
       'months':content[0],
       'avgBalance':parseInt(content[4]),
      }
      avgBalanceGraph.push(myobject)
    })
    exceldata.map((e)=>{
      let content = e.slice(0,5)
      let myobject = {
       'months':content[0],
       'netCashFlow':parseInt(content[3]),
      }
      netCashFlowGraph.push(myobject)
    })
  },[])
  return (
    <div className="ag-theme-alpine"  >
    <div className="charts">
    <AgGridReact
      rowData={OverViewRows} // Row Data for Rows
      columnDefs={Overviewcolumns}
      defaultColDef={defaultColDef}
      rowStyle={rowStyle}
    />
    <AgChartsReact options={netCashFlowOptions} />
    <AgChartsReact options={options} />
    <AgChartsReact options={avgBalanceOptions} />
    </div>
  </div>
  )
}

export default OverView
