import React, { useState } from 'react'
import './Login.css'
import { useNavigate, Link } from 'react-router-dom';
import Loader from './Loader';

const SignUp = (props) => {
  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);

  const URL = window.globalURL

  const [Auth, setAuth] = useState({
    name: '',
    email: '',
    password: '',
    mobile_no: ''
  })

  const onChange = (e) => {
    setAuth({ ...Auth, [e.target.name]: e.target.value })
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitForm = async (e) => {
    e.preventDefault();


    if (!Auth.email || !Auth.password || !Auth.name || !Auth.mobile_no) {
      alert('Please enter all the required details...');
      return;
    }
    if (!isValidEmail(Auth.email)) {
      alert('Please enter a valid email address.');
      return;
    }
    if (Auth.password.length < 8) {
      alert('Password must be at least 8 characters...');
      return;
    }

    setSpinner(true);



try{
    const response = await fetch(`${URL}/register`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: Auth.name, email: Auth.email, mobile_no: Auth.mobile_no, password: Auth.password }),
    });

    const json = await response.json();
    setSpinner(false)
    if (json.message === 'Registration successful') {
      localStorage.setItem('user_email', Auth.email)
      props.showAlert("Confirmation Code sent to mail (Check spam folder also)", 'success')
      navigate('/confirmation')
    }
    else {
      console.log(json);
      props.showAlert(json.message.message, 'error')
    }
  }
  catch (error) {
    console.error('An error occurred:', error);
  }
  }
  return (
    <div>
      <div className="auth-wrapper">
        {spinner && <Loader />}
        <div className="auth-inner">

          <form onSubmit={submitForm}>
            <h3>Sign Up</h3>
            <div className="mb-3">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                name='name'
                id='name'
                required
                onChange={onChange}
                maxLength={75}
                value={Auth.name}
              />
            </div>
            <div className="mb-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                name='email'
                id='email'
                required
                onChange={onChange}
                maxLength={75}
                value={Auth.email}
              />
            </div>

            <div className="mb-3">
              <label>Phone No</label>
              <input
                type="text"
                name='mobile_no'
                id='mobile_no'
                pattern="[0-9+]+"
                title="Only numbers and the plus sign are allowed"
                onChange={onChange}
                required
                value={Auth.mobile_no}
                minLength={10}
                maxLength={15}
                className="form-control"
              />
            </div>


            <div className="mb-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name='password'
                required
                id='password'
                minLength={8}
                maxLength={25}
                onChange={onChange}
                
                value={Auth.password}
              />
            </div>
            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                Sign Up
              </button>
            </div>
            <p className="forgot-password text-right">
              Already registered <Link to="/login">sign in?</Link>
            </p>
          </form>
          
        </div></div> </div>
  )
}

export default SignUp
