import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import Loader from './Loader';

const CreateNewReport = (props) => {

  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const URL = window.globalURL

  const [Auth, setAuth] = useState({
    user_access_token: localStorage.getItem('accessToken'),
    user_report_id: props.randomNumber,
    user_email_id: localStorage.getItem('user_email'),
    report_name: '',
    report_ref_id: ''
  })

  const onChange = (e) => {
    setAuth({ ...Auth, [e.target.name]: e.target.value })
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setSpinner(true)
    // Callong create new report Api
try{
    const response = await fetch(`${URL}/create-report`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_access_token: Auth.user_access_token, user_email_id: Auth.user_email_id, user_report_id: Auth.user_report_id, report_name: Auth.report_name, report_ref_id: Auth.report_ref_id }),
    });

    const json = await response.json();
    setSpinner(false);
    if (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException'){
                setSpinner(false);
                props.showAlert("Session expired. Please log in again to continue...", 'error')
                localStorage.clear();
                navigate('/login')
                return;
            }
    if(json.message ==="Report created successfully"){
    navigate('/upload-file')
    sessionStorage.setItem('user_report_id', json.report_id)
    sessionStorage.setItem('report_name', Auth.report_name)
    }
    else{
      localStorage.clear();
      navigate('/login')
    }
  }
  catch (error) {
    console.error('An error occurred:', error);
  }
  }
  return (
    <>
      <div className='new-report-form'>
        <h1>Create New Report</h1>
        
        {spinner && <Loader />}
        <hr />

        <form onSubmit={submitForm}>
          <input type="text" onChange={onChange} autoComplete='off' required name='report_name' id='report_name' placeholder='Report Name' maxLength={90}/>
          <input type="text" onChange={onChange} autoComplete='off' required name='report_ref_id' id='report_ref_id' placeholder='Report Reference Number' maxLength={90}/>
          <div className="form-submit">

            <Link onClick={()=>{
              props.newReport(false)
            }}> <button className='button-blue'>Back</button></Link>

            <button className='button-green' type='submit'>Next</button>
          </div>
        </form>

      </div>
    </>
  )
}

export default CreateNewReport
