import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ComingSoonPopUp from './ComingSoonPopUp';
import ExpiringAlert from './ExpiringAlert';
import PlanExpired from './PlanExpired';

const HomePage = () => {
  const navigate = useNavigate();
  const URL = window.globalURL
  const [showingTime, setShowingTime] = useState('');
  const [Auth, setAuth] = useState({
    user_email_id: localStorage.getItem('user_email'),
    user_access_token: localStorage.getItem('accessToken')
  })
  const [userData, setUserData] = useState({
    gstn_analysed:"",
    subsc_type:"",
    rep_analysed:"",
    billing_status:"",
    user_type:"",
    payment_link:""
  })
  const [comingpopup, setcommingpopup] = useState(false);
  const [day, setDays] = useState() 
  const [planExpired, setplanExpired] = useState(false)
  var current = new Date();
  const getData = async () => {
    try {
      const response = await fetch(`${URL}/get-my-account`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_email_id: Auth.user_email_id, user_access_token: Auth.user_access_token }),

      });
      const json = await response.json();

      console.log(json.message);
      setUserData({
        gstn_analysed: "0",
        subsc_type: json.message.subsc_type,
        rep_analysed: json.message.bsa_rep_analysed,
        billing_status:json.message.billing_status,
        user_type:json.message.user_type,
        payment_link:json.message.payment_link,

      })
      const targetDate = new Date(json.message.pl_exp_date);
      var current = new Date();
      let years = targetDate.getFullYear() - current.getFullYear();
  let months =( targetDate.getMonth()+1) - (current.getMonth()+1);
  let days = targetDate.getDate() - current.getDate();


if (current < targetDate) {
      if (days < 0) {
        const prevMonthLastDay = new Date(targetDate.getFullYear(), targetDate.getMonth(), 0).getDate();
        days += prevMonthLastDay;
        months--;
      }
      if (months < 0) {
        months += 12;
        years--;
      }
      if(years>0){
        setShowingTime(`${years} years `)
      }
      else if(months>0){
        setShowingTime(`${months} months `)
      }
      else{
        setShowingTime(`${days} days`)
        setDays(days)
      }
    }else{

      setShowingTime('Renewal pending');
        setDays(0)


    }





  isExpiry(days, json.message.subsc_type);
    }
    catch (error) {
      console.error('An error occurred:', error);
    }
  }
  // It acces token is not available then redirect to Loign page

  useEffect(()=>{
    if(!localStorage.getItem('accessToken')){
      navigate('/login')
    }
     getData();
  },[])
const showingExpireDetails  = ()=>{
  /*if(showingTime.includes('days') && day>current.getDate() && day<=10){
    return true
  }
  else{
    return false;
  }*/

  if ((userData.billing_status === 'AB-NTP' || userData.billing_status==='MB-NTP')){
  return true
  }else{
    console.log(userData.billing_status)

  return false
  }
}
const isExpiry = (days,subsc_type)=>{

  
  if(days<=0){

    if (subsc_type.trim().toLowerCase() !== "free trail" && (userData.billing_status==='MB-NTP'||userData.billing_status === 'AB-NTP')) {
      setplanExpired(true);
    }else{  }
    
  }else{
    console.log('console.log(userData.subsc_type);111');

  }
}
  return (
    <>
    { planExpired && <PlanExpired planExpired = {setplanExpired}/>}
      <div className="expiring-content">
        {
          showingExpireDetails() ? <ExpiringAlert days={day} link={userData.payment_link}  additionalData={userData.subsc_type}/> : null
        }
      </div>
    <div className='main-home-page'>
      <div className="left-home-page">
        <div className="quick-link">
          <div className="quick-link-heading">
            <h2>Quick Links</h2>
          </div>
          <hr />
          <div className="quick-link-content">
            <Link to="/reports"><li><i className="fa-solid fa-plus"></i>NEW REPORT</li></Link>
            <Link to='/reports'><li><i className="fa-solid fa-table"></i>MANAGE REPORTS</li></Link>
          {
            localStorage.getItem('user_type')==='company_admin'?
          <Link to='/team-report'><li><i className="fa-solid fa-table"></i>TEAM REPORTS</li></Link>:null
          }
            <Link onClick={()=>setcommingpopup(true)}><li><i className="fa-solid fa-cart-plus"></i>SUBSCRIBE NOW</li></Link>
            <Link onClick={()=>setcommingpopup(true)}><li><i className="fa-regular fa-circle-question"></i>HELP DOCUMENTATION</li></Link>
            <Link onClick={()=>setcommingpopup(true)}><li><i className="fa-solid fa-gear"></i>API DOCUMENTATION</li></Link>
          </div>
        </div>
      </div>

      <div className="center-home-page">
      <div className="center-boxes">
      <div className="cente-box">

        <div className="center-box-left">
          <p>Bank Account</p>
          <p>Analysed</p>

          <p className='text-blue'>{userData.rep_analysed}</p>
        </div>
        <div className="center-box-right">
        <i className="fa-regular fa-folder"></i>
        </div>

      </div>
      <div className="cente-box">

        <div className="center-box-left">
          <p>GSTIN Report </p>
          <p>Analysed</p>
          <p className='text-blue'>{userData.gstn_analysed}</p>
        </div>
        <div className="center-box-right">
        <i className="fa-regular fa-folder"></i>
        </div>

      </div>
      <div className="cente-box">

        <div className="center-box-left">
          <p>Subscription</p>
          <p>Plan</p>
          <p className='text-blue'>{userData.subsc_type}</p>
        </div>
        <div className="center-box-right">
        <i className="fa-regular fa-folder"></i>
        </div>

      </div>
      <div className="cente-box">

        <div className="center-box-left">
          <p>Next subscription</p>
          <p></p>
          <p className='text-blue'>{showingTime}</p>
        </div>
        <div className="center-box-right">
        <i className="fa-regular fa-folder"></i>
        </div>

      </div>
      </div>
      </div>

      <div className="right-home-page">
        <div className="report-status">
          <div className="report-status-heading">
            <h2>Report Status</h2>
          </div>
          <hr />
          <div className="report-status-content">
            <li><i className="fa-regular fa-star green" ></i>Analysed</li>
            <li><i className="fa-regular fa-star blue"></i>In Progress</li>
            <li><i className="fa-regular fa-star red"></i> Analysed On Hold</li>
          </div>
        </div>
      </div>
    </div>
  {comingpopup &&  <ComingSoonPopUp comingpopup = {setcommingpopup}/>}
</>
  )
}

export default HomePage
