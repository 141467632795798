import React from 'react'
import { Link } from 'react-router-dom'
const ExpiringAlert = (props) => {
  const { days, link, additionalData } = props;

  const renderContent = () => {
    if (link && link.length > 6 && additionalData !== 'Free Trail') {
      console.log({ link });
      return (
        <p>
          Please pay the subscription amount to avoid account suspension. Click{' '}
          <Link to={link} target="_blank" rel="noopener noreferrer">Pay now</Link> to pay.
        </p>
      );
    } else if (additionalData !== 'Free Trail') {
      console.log({ link });
      return (
        <p>
          Please contact our team to and renew the subscription.
        </p>
      );
    }else{


     return (
       <p>
  Congratulations. Your Free Trial has been activated. To start analyzing, go to {' '}
  <Link to="http://localhost:3000/reports" rel="noopener noreferrer">Reports</Link> page and click new report at the top.
</p>

      );




    }
  };

  return (
    <div className="expiring">
      <i className="fa-solid fa-info"></i>
      <div className="expiring-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default ExpiringAlert