import React, {useEffect, useState} from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css';
const CounterPartyMonthly = (props) => {
  const {realdata, value} = props
  const [arraycall, setarray] = useState([])
  const [random, setrandom]= useState('none')
    // It is for AG grid Column Data
    useEffect(()=>{
      realdata[value].map((e)=>{
        const arr = [];
        e.map((elem, i)=>{
          arr.push(i)
        })
        for(let i =0; i<10; i++){
          if(!arr.includes(i)){
            e[i] = '-'
          }
        }
      })
      arraycall[value] = realdata[value];
      setrandom(0)
    },[])
    var columns = arraycall[value] && arraycall[value].length > 0
    ? arraycall[value][1].map((header, index) => ({
      field: `col${index}`,
      headerName: header,
      filter: true,
      width: 200,
      valueFormatter: params => {
        if (params.value === undefined) {
          return 'none';
        }
        return params.value.toLocaleString('en-IN')
      } 
    }))
    : [];


  // It is for AG grid Row Data

  const rows = arraycall[value] && arraycall[value].length > 1
    ? arraycall[value].slice(2).map((row, index) => {
      const rowData = { id: index };
      row.forEach((cell = 'none', cellIndex) => {
        rowData[`col${cellIndex}`] = cell || '0'
      });
      return rowData;
    })
    : [];


    // Applicable for all Column

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const rowStyle = {
    fontSize: '0.9em',
  }

  return (
    <div className="ag-theme-alpine"  >
    <AgGridReact
     columnDefs={columns}
     rowData={rows}
      animateRows={true} // Optional - set to 'true' to have rows animate when sorted
      defaultColDef={defaultColDef}
      rowStyle={rowStyle}

    />
  </div>
  )
}

export default CounterPartyMonthly
