import React, { useEffect, useState } from 'react'
import './css/NewReports.css'
import NewReportHomePage from './NewReportHomePage'
import Loader from './Loader'
import { Link } from 'react-router-dom'
import ReportCreatedPopUp from './ReportCreatedPopUp'
import SubscriptionOver from './SubscriptionOver'
import { useNavigate  } from 'react-router-dom';

const UplaodFile = (props) => {
  const navigate = useNavigate();
  const [submitForm, setSubmitForm] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [setsingh, setsetsingh] = useState(new Set());
  const [filesdata, setfilesData] = useState([])
  const [data, setData] = useState([]);
  const [reportCreatedPopUp, setReportCreatedPopUp] = useState(false)
  const [acc_id, setacc_id] = useState('');
  const [allData, setallData] = useState([]);
  const [subscriptionOver, setSubscriptionOver] = useState(false);
  let [filenumber, setFilenNumber] = useState(0);
  const [Auth, setAuth] = useState({
    user_access_token: localStorage.getItem('accessToken'),
    user_report_id: sessionStorage.getItem('user_report_id'),
    user_report_name: sessionStorage.getItem('report_name'),
    user_email_id: localStorage.getItem('user_email')
  })

  // when we click on add new Account then theses data will be a input

  const [createAccountData, setCreateAccountData] = useState({
    user_access_token: localStorage.getItem('accessToken'),
    user_report_id: sessionStorage.getItem('user_report_id'),
    user_email_id: localStorage.getItem('user_email')
  })

  const URL = window.globalURL

  const fetchData = async () => {

    setSpinner(true);
    try {
      const response = await fetch(`${URL}/get-account`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_access_token: Auth.user_access_token, user_report_id: Auth.user_report_id, user_email_id: Auth.user_email_id }),
      });

      const json = await response.json();
      console.log('--------------------------------------------------json get account');
      console.log(json);

      if (json.message && json.message.code && (json.message.code==='NotAuthorizedException'||json.code=== 'TokenExpiredException')){
        setSpinner(false);
        props.showAlert("Session expired. Please log in again to continue...", 'error')
        localStorage.clear();
        navigate('/login');
        return;
      }
      setData(json);
      setSpinner(false);
    }
    catch (error) {
      console.error('An error occurred:', error);
      setSpinner(false);
    }
  }
  useEffect(() => {
    const datetime = new Date().getTime();
    setacc_id(`acc_${datetime}`)
    fetchData();
    localStorage.removeItem('fileAvailable')
  }, [])
  const createAccount = async () => {
    const objectLength = Object.keys(data).length;
    if (objectLength <= 8) {
      const datetime = new Date().getTime();
      setacc_id(`acc_${datetime}`)
      setSpinner(true)
      try {
        const response = await fetch(`${URL}/create-account`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ user_access_token: createAccountData.user_access_token, user_report_id: createAccountData.user_report_id, user_email_id: createAccountData.user_email_id, acc_id: acc_id }),
        });

        const json = await response.json();
        setSpinner(false);
        if (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException'){
                setSpinner(false);
                props.showAlert("Session expired. Please log in again to continue...", 'error')
                localStorage.clear();
                navigate('/login')
                return;
            }
        fetchData();
        props.showAlert("New account created successfully", 'success')
      }
      catch (error) {
        console.error('An error occurred:', error);
      }
    }
    else {
      props.showAlert("Account creation limit exceeded (Maximum 8 Account Per Report)", 'error')

    }
  }
  const getfromchild = (jsonobject) => {
    const newSet = new Set(setsingh);
    var keyOfObject;
    for (let key in jsonobject) {
      keyOfObject = key;
      if (setsingh.has(keyOfObject)) {
        for (let x in allData) {
          var y = JSON.stringify(allData[x])
          const openBraceIndex = y.indexOf('{');

          // Find the index of the first closing quotation mark (") after the opening curly brace
          const firstQuoteIndex = y.indexOf('"', openBraceIndex + 1);

          // Find the index of the second closing quotation mark (") after the opening curly brace
          const secondQuoteIndex = y.indexOf('"', firstQuoteIndex + 1);

          // Extract the value between the first pair of quotation marks
          const extractedValue = y.slice(firstQuoteIndex + 1, secondQuoteIndex);
          if (extractedValue == keyOfObject) {
            allData[x] = jsonobject
          }
        }
      }
      else {
        allData.push(jsonobject)
        newSet.add(key);
        setsingh.add(key);
      }
    }

  }
  const saveddata = async () => {
    setSpinner(true)
   setSubmitForm(true);

    setTimeout(async() => {
      let successlength = 0;
      let accountNumbersSet = new Set();

      for (let i = 0; i < allData.length; i++) {
        let key1 = ''
        for (let key in allData[i]) {
          key1 = key;
        }
        let currentAccountNumber = allData[i][key1].acc_no;

        if (allData[i][key1].bank_name === 'null'||allData[i][key1].bank_name === '') {
          props.showAlert("Please select the bank name", 'error')
        }
        else if (!/^\d{8,20}$/.test(allData[i][key1].acc_no)) {
            props.showAlert("Please fill the correct account number", 'error');
        }
        else if (allData[i][key1].acc_no === 'null'||allData[i][key1].acc_no === '') {
          props.showAlert("Please fill the account number", 'error')
        }
        else if (allData[i][key1].acc_type === 'null'||allData[i][key1].acc_type === '') {
          props.showAlert("Please select the account type", 'error')
        }
        else if(!localStorage.getItem('fileAvailable')){
                props.showAlert("Atleast one file should be uploaded to process", 'error')
        }
        else if (accountNumbersSet.has(currentAccountNumber)) {
            props.showAlert("Duplicate account number found. Please make sure to give correct account number to each account", 'error');
        } 
        else {
          successlength+=1;
          accountNumbersSet.add(currentAccountNumber);
        }
      }
      if(successlength===allData.length){
        const response = await fetch(`${URL}/start-process`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ user_access_token: createAccountData.user_access_token, user_email_id: createAccountData.user_email_id, user_report_id: createAccountData.user_report_id, accounts: allData }),
        });
        const json = await response.json();
        if (json.message == "Process started successfully...") {
          setSpinner(false);
          localStorage.removeItem('fileAvailable');
          setReportCreatedPopUp(true);
        }
        else if(json.message == "Your subscription is cancelled"){
           setSpinner(false);
          setSubscriptionOver(true);
        }
        else if(json.message == "Your subscription is cancelled"){
           setSpinner(false);
           setSubscriptionOver(true);
        }
        else if (json.message.code==='NotAuthorizedException'||json.message.code=== 'TokenExpiredException'){
                setSpinner(false);
                props.showAlert("Session expired. Please log in again to continue...", 'error')
                localStorage.clear();
                navigate('/login')
                return;
        }
        else {
        setSpinner(false);
        localStorage.removeItem('fileAvailable')
        props.showAlert("Some Error Occured", 'error')
        }
      }
      setSubmitForm(false)
      setSpinner(false);

    }, 3000);

  }

  return (
    <>
    {subscriptionOver && <SubscriptionOver/>}
      <div>
        <div className="report-page-heading">
        <h1> <u onClick={()=>navigate(-1)} style={{color: 'rgb(42, 83, 149)',cursor:'pointer'}}> Reports</u> &rarr; {Auth.user_report_name}</h1>
        </div>
        {Object.keys(data).map((key, index) => (
          <div key={index}>
           <NewReportHomePage index={index} showAlert={props.showAlert} file={filesdata} acc_id={key} bankDetails={data[key]}
             getfromchild={getfromchild} fetchData={fetchData} submitForm={submitForm} spin = {setSpinner} filenumber = {setFilenNumber} 
             setsingh = {setsingh} allData  = {allData} setallData = {setallData}/>
          </div>
        ))}
       { <div className='add-account'>
          {spinner && <Loader />}
          <button className='button-upload add-account-uplaod' style={{display:spinner?'none':'flex'}} disabled={spinner} onClick={createAccount}><i className="fa-solid fa-plus"></i>Add New Account</button>
        </div>}
        <div className='add-account'>
  {spinner && <Loader />}
  <button
    className='button-upload add-account-uplaod'
    style={{ display: 'none' }}
    disabled={spinner}
    onClick={createAccount}
  >
    <i className="fa-solid fa-plus"></i>Add New Account
  </button>
</div>
        <div className="row-input row-input-button-uplaod" >
          <button disabled={spinner} className='button-upload save-uplaod' type="submit" onClick={saveddata} ><i className="fa-regular fa-floppy-disk"></i>Save</button>
          <Link to='/reports'> <button disabled={spinner} className='button-upload cancel-uplaod' type="reset"><i className="fa-solid fa-xmark"></i>Cancel</button></Link>
        </div>
      </div>
      {reportCreatedPopUp && <ReportCreatedPopUp />}
    </>
  )
}

export default UplaodFile;
